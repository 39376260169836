import { useState, useContext, useEffect } from 'react';
import { UserSummaryContext } from '../contexts/UserSummaryContext';
import { Image, Spinner, CloseButton } from 'react-bootstrap';
import UserIcon from '../images/unknow_user_image.jpg';
import DefaultBannerImage from '../images/user-summary_default-banner.png';
import { connect, useDispatch, useSelector } from 'react-redux';
import { multi_userActions } from '../actions/multi_user.actions.js';
import { UserProfileStatus, UserAvatar } from '../components';
import { adminBaseURL } from '../utils/commonUtils';

function UserSummary(props) {
  const { userSummaryRef, currentUser, loading, availability, noCurrentUser } = props
  const [alignLeft, setAlignLeft] = useState(false);
  const { innerWidth, innerHeight } = window;
  const { userSummaryDetails, setUserSummaryDetails, isActive, setIsActive } = useContext(UserSummaryContext);
  const dispatch = useDispatch();
  const userProfileBaseUrl = adminBaseURL("user-profiles");

  useEffect(() => {
    if(isActive){
      userSummaryRef?.current?.focus();

      dispatch(multi_userActions.showUser(userSummaryDetails.user_id, userSummaryDetails.workspace_id, userSummaryDetails.organization_id));
    } else{
      dispatch(multi_userActions.resetUser());
    }
  }, [isActive]);

  const getStyle = () => {
    let styles = {}

    if (innerWidth >= (userSummaryDetails.boundary?.left + 30 + 300)){
      styles.left = `${userSummaryDetails.boundary?.left + 30}px`;
    } else {
      styles.left = `${(userSummaryDetails.boundary?.left + 0) - 300 }px`;
    }

    if (innerHeight >= (userSummaryDetails.boundary?.top + 30 + 333)){
      styles.top = `${userSummaryDetails.boundary?.top}px`;
    } else {
      styles.top = `${(userSummaryDetails.boundary?.top + 30 ) - 233 }px`;
    }

    return styles
  }

  const handleClose = () => {
    setIsActive(false)
  }

  const profileClickHandler = (e) =>{
    e.stopPropagation();

    window.location = `${userProfileBaseUrl}/${userSummaryDetails?.user_id}`

    return true;
  }

  const bannerStyle = () => {
    return {
      backgroundImage: `url(${DefaultBannerImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }
  }

  return (
    <div
      className={`user-profile_container d-flex ${isActive ? 'active' : 'inactive'}`}
      style={getStyle()}
      ref={userSummaryRef}
    >
      <div className="close-container text-center d-flex align-items-center d-none">
        <CloseButton variant="white" title="Close" className="w-100" onClick={handleClose}/>
      </div>
      { loading ?
          <div className="clearfix w-50 m-auto">
            <div className="spinner-container">
              <Spinner animation="border" />
            </div>
          </div>
        :
          <div className="position-relative w-100">
            <div
              className="text-center user-profile_image mb-3"
              style={bannerStyle()}
            >
              <UserAvatar
                name={currentUser?.name}
                name_initials={currentUser?.name_initials}
                icon={currentUser?.logo?.icon?.url}
                size={60}
                bordered={true}
                containerClassName={'avatar-container'}
              />
            </div>

            <div className="user-profile_details p-3 vertical-scroll_thin">
              {
                availability ?
                  <>
                    <h4>
                      { currentUser?.name }
                    </h4>
                    {
                      currentUser?.roles?.length > 0 &&
                      <ul className="user-profile_details__roles mb-2 list-unstyled">
                        {
                          currentUser?.roles
                            .map((r, index) => (
                              <li key={index}>{r.toLowerCase()}</li>
                            ))
                        }
                      </ul>
                    }

                    {
                      false &&
                      <UserProfileStatus className='mb-4'/>
                    }
                  </>
                :
                  noCurrentUser &&
                  <b>
                    Removed
                  </b>
              }
            </div>

            {
              availability &&
              <div
                className="px-3 w-100 position-absolute"
                style={{ bottom: '16px' }}
              >
                <div className="view_profile-button" onClick={profileClickHandler}>
                  View Profile
                </div>
              </div>
            }
          </div>
      }
    </div>
  );
}
const mapStateToProps = (state, props) => {
  return {
    loading: state.user_profile.user_summary_loading,
    currentUser: state.user_profile.currentUser,
    noCurrentUser: state.user_profile.noCurrentUser,
    availability: state.user_profile.availability
  };
};

export default connect(mapStateToProps, {})(UserSummary);
