import React, { useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {useDetectOutsideClick} from "../../../../utils/useDetectOutsideClick";
import { UserThumbnails, CircleAddIcon } from '../../../../components';
import AssignedUserNew from "../../AssignedUserPage/new";
import { assigned_userActions } from '../../../../actions';

function ParticipantField(props) {
  const {
    can_access_assigned_user,
    assigned_users,
    workspace_id,
    content_group_id
  } = props;
  const dispatch = useDispatch();

  const activeDropDownPopup = (id) => {
    if(id) {
      setIsActive({[id]: true});
    }else {
      setIsActive(false);
    }
  }

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  const handleOpenAssignedUserEditPopup = (id, fromList) =>{
    dispatch(assigned_userActions.newAssignedUser(workspace_id, id, activeDropDownPopup));
  }

  return (
    <div style={{position: 'relative'}} className="text-center">
      {(assigned_users?.length > 0) ?
          <div onClick={ can_access_assigned_user ? () => handleOpenAssignedUserEditPopup(content_group_id) : null}>
            <UserThumbnails users={assigned_users} disable_users_info={true}/>
          </div>
        :
          can_access_assigned_user &&
          <div
            className="button-icon cursor-pointer"
            onClick={() => handleOpenAssignedUserEditPopup(content_group_id)}
          >
            <CircleAddIcon />
          </div>
      }

      <nav
        ref={dropdownRef}
        className={`assigned_user_list ${isActive[content_group_id] ? "active" : "inactive"}`}
        id="new_assigned_user_list">
        <AssignedUserNew
          workspace_id={workspace_id}
          content_group_id={content_group_id}
          closeDropDownPopup={activeDropDownPopup}
          fromList={false}
        />
      </nav>

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.content_group.loading
  };
};

export default connect(mapStateToProps, {})(ParticipantField);
