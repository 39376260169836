import { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from "react-redux";
import { Image, Row, Col, Button } from 'react-bootstrap';
import { UserAvatar, FileAttachment, ImageAttachment, ImageAttachments } from '../components';
import CommentApprovalSendIcon from '../images/icons/comment-approval-send.svg';
import CommentRevisionIcon from '../images/icons/comment-revision.svg';
import CommentApprovalCancelIcon from '../images/icons/comment-approval-cancel.svg';
import CommentCheckIcon from '../images/icons/comment-approved.svg';
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';
import { ChatContext } from '../contexts/ChatContext'

function MessageBubble(props) {
  const {
    own,
    extra_heading_content,
    user_name,
    user_name_initials,
    user_icon,
    message,
    date,
    event_type,
    selectCommentToReply,
    id,
    highlighted,
    is_a_reply,
    attachments,
    workspace_id,
    organization_id,
    content_group_id,
    setImagePopUp,
    user_id,
    renderParentComment,
    parent_id,
    data
  } = props;

  const [selected, setSelected] = useState(false);
  const messageRef = useRef();

  const arrayChunk = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };

  const {
    can_access_comment
  } = useContext(ChatContext);

  return (
    <div
      className={
        `message ${ own ? " message--own" : "message--other"}
        ${selected ? 'hovered' : null}
        ${highlighted ? 'highlighted' : null }`
      }
      ref={messageRef}
    >
      <div className="d-flex">
        <div className="message-user">
          <UserAvatar
            icon={ user_icon }
            name={ user_name }
            name_initials={ user_name_initials }
            size="40"
            user_id={user_id}
            openUserSummary={true}
            workspace_id={workspace_id}
            organization_id={organization_id}
          />
        </div>
        <div className="px-2" style={{width: "calc(100% - 40px)"}}>
          <div className="">
            <div className="description-container">
              { extra_heading_content }
              { user_name &&
                <div className="description__user mb-2">
                  { user_name }
                </div>
              }
            </div>
          </div>

          <div className="description-container">
            {
              data?.parent &&
              <div className="mb-2">
                { renderParentComment(data) }
              </div>
            }
            {
              (message || event_type) &&

              <div className={ "description" + ( event_type ? " description--with-action" : "" ) }>
                <span dangerouslySetInnerHTML={{ __html:
                  linkifyHtml((message || ''), { target: '_blank' })
                }} />

                <span
                  className={"action-icon ml-2" + (event_type ? "" : " d-none")  }
                  style={ message === '' || message === null ? { right: 'initial' } : null }
                >
                  { event_type == "request_revision" &&
                    <img src={CommentRevisionIcon} title="Requested Revisions" />
                  }

                  { event_type == "submit_for_approval" &&
                    <img src={CommentApprovalSendIcon} title="Submitted for Approval" />
                  }

                  { event_type === "void_submission" &&
                    <img src={CommentRevisionIcon} title="Voided Submission" />
                  }

                  { event_type == "approve" &&
                    <img src={CommentCheckIcon} title="Approved" />
                  }

                  { event_type == "accept_request" &&
                    <img src={CommentCheckIcon} title="Accepted Request" />
                  }

                  { event_type == "reject_request" &&
                    <img src={CommentRevisionIcon} title="Rejected Request" />
                  }
                </span>
              </div>
            }
            {
              attachments && attachments.length > 0 &&
              <div className="attachments-container">
                <div>
                  {
                    attachments.filter((attach) => attach.file_thumbnail).length > 0 &&
                      <div
                        className={`pr-0 mb-2`}
                      >

                        {
                          arrayChunk(attachments.filter((attach) => attach.file_thumbnail), 3).map((row, i) => (
                            <div key={i} className="mb-1 d-flex">
                              {row.map((attachment, i) => (
                                <ImageAttachment
                                  filename={ attachment.filename }
                                  thumbnail={ attachment.file_thumbnail }
                                  image_url={ attachment.image_url }
                                  attachment_id={ attachment.id }
                                  workspace_id={ workspace_id }
                                  content_group_id={ content_group_id }
                                  comment_id={ id }
                                  attachment_id={ attachment.id }
                                  setSelected={ setSelected }
                                  key={ attachment.id }
                                  own={ own }
                                  viewable={ true }
                                  dynamic_icon={ true }
                                  className={attachments.filter((attach) => attach.file_thumbnail).length > 2 ? null : 'by_2'}
                                />
                              ))}
                            </div>
                          ))
                        }
                      </div>
                  }

                  {
                    attachments.filter((attach) => attach.file_thumbnail == null || attach.file_thumbnail == false).map((attachment, index) =>(
                      <div
                        key={ attachment.id }
                        className="pr-0"
                      >
                        <FileAttachment
                          filename={ attachment.filename }
                          workspace_id={ workspace_id }
                          content_group_id={ content_group_id }
                          comment_id={ id }
                          attachment_id={ attachment.id }
                          setSelected={ setSelected }
                          own={ own }
                          downloadable={ true }
                        />
                      </div>
                    ))
                  }

                </div>
              </div>
            }

            <div className="message-date">
              {
                can_access_comment &&
                <div className="message-reply-button mr-2" onClick={() => selectCommentToReply(id, messageRef)}>
                  Reply
                </div>
              }

              { date }
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export { MessageBubble };
