import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect,  useDispatch } from 'react-redux';
import { contentLabelActions, content_groupActions } from '../../../actions';
import { Button } from 'react-bootstrap';
import { LoadSpinner} from '../../../components';
import { reduxForm, Field, change  } from "redux-form";
import ContentLabelSelector from './selector';
import ContentLabelForm from './form';
import CloseIcon from '../../../images/close.png'

function ContentLabelPopup(props) {
  const dispatch = useDispatch();
  const {
    content_labels,
    handleSubmit,
    labels,
    workspaceId,
    contentGroupId,
    handleClose,
    can_access_edit_label,
    content_popup_notification
  } = props
  const [ edit_label, setEditLabel] = useState(null)
  const [ label_id, setLabelId] = useState(null)
  const [ mode, setMode] = useState("list");

  useEffect(() => {
    dispatch(contentLabelActions.getContentLabels(workspaceId));
  }, []);

  const changeMode = (mode, event) => {
    setMode(mode)
    event.stopPropagation()
    event.preventDefault()
  }

  /* Popup Modal*/
  const [popup, setPopUp] = useState({ show: false, id: null});

  const handleContentLabelSelect = (contentLabelId, value, close_popup) => {
    let requestParams = { label_id: contentLabelId, assigned: value }

    let callback = () => {
      if(close_popup){
        handleClose();
      }
    }

    dispatch(content_groupActions.assignContentLabelToContentGroup(workspaceId, contentGroupId, requestParams, content_popup_notification, callback))
  }

  const handleContentLabelEdit = (contentLabelId, event) => {
    setLabelId(contentLabelId)
    setEditLabel(content_labels && content_labels.find(item => item.id == contentLabelId))
    changeMode('edit', event)
  }

  const handleContentLabelDelete = (contentLabelId, event) => {
    dispatch(contentLabelActions.deleteContentLabel(workspaceId, contentLabelId, contentGroupId))
    setMode("list")
  }

  const setModeAfterScuess = () => {
    setMode('list')
  }

  const showResults = (mode, value) => {
    if(mode == "create_mode") {
      dispatch(contentLabelActions.createContentLabel(workspaceId, value, setModeAfterScuess))
    }else {
      dispatch(contentLabelActions.updateContentLabel(workspaceId, value, label_id, setModeAfterScuess))
    }
  }

  return (
    <div className="container-fluid">
      { props.loading && <LoadSpinner />}

      <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}/>

      <h4 className="content-label-popup__title font-weight-bold mb-2">Label</h4>

      <div style={{ clear: "both" }} />

      { mode == 'list' &&
        <div>
          <ul className="content-label-popup__list">
            { content_labels && content_labels.map((data, index) => (
              <li key={'label_'+data.id}>
                <ContentLabelSelector
                  selected={ labels?.find(item => item.id == data.id) }
                  labelId={ data.id }
                  labelName={ data.name }
                  colorHex={ data.color_hex }
                  onUpdate={ handleContentLabelSelect }
                  onEdit={ handleContentLabelEdit }
                  onDelete={ handleContentLabelDelete }
                  can_access_edit_label={ can_access_edit_label}
                />
              </li>
            ))}
          </ul>

          <span className="content-label-popup__add-button" onClick={ (e) => changeMode('new', e) }>
            {
              content_labels?.length === 0 ?
                "Create Label"
              :
                "Add Label"
            }

          </span>
        </div>
      }

      { mode == 'new' &&
        <div className='lable_form'>
          <ContentLabelForm
            onSubmit={(value) => showResults('create_mode', value)}
            changeMode={(e) => changeMode('list', e)}
            formMode="new_mode"
          />
        </div>
      }

      { mode == 'edit' &&
        <div className='lable_form'>
          <ContentLabelForm
            onSubmit={(value) => showResults('edit_mode', value)}
            changeMode={(e) => changeMode('list', e)}
            initialValues={edit_label}
            labelId={label_id}
            formMode="edit_mode"
            onDelete={ handleContentLabelDelete }
          />
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.content.content_loading,
    content_group: state.content.currentContentGroup,
    content_labels: state.content_label.contentLabels
  };
};

export default connect(mapStateToProps, { contentLabelActions })(ContentLabelPopup);
