function EditIcon(props) {
  const { className, color } = props

  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
      	viewBox="0 0 24 24"
        fill={color}
      >
        <path d="M3,17.46v3.04C3,20.78,3.22,21,3.5,21h3.04c0.13,0,0.26-0.05,0.35-0.15L17.81,9.94l-3.75-3.75L3.15,17.1
        	C3.05,17.2,3,17.32,3,17.46z M20.71,7.04c0.39-0.39,0.39-1.02,0-1.41l-2.34-2.34c-0.39-0.39-1.02-0.39-1.41,0l-1.83,1.83l3.75,3.75
        	C18.88,8.87,20.71,7.04,20.71,7.04z"/>
      </svg>
    </>
  );
}

export { EditIcon };
