import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { work_spaceActions, organizationActions } from '../../../actions';
import { Accordion, Button } from 'react-bootstrap';
import { adminBaseURL } from '../../../utils/commonUtils';
import { CircleAddIcon, UpgradeButton, UpprovallPlusTitle } from "../../../components";
import { WorkspaceListContext } from '../../../contexts/WorkspaceListContext';

function Sidebar(props) {
  const dispatch = useDispatch();
  const { organizations, can_create_organization } = props
  const {
    default_modes,
    selected_organization,
    setSelectedOrganization,
    selected_tab,
    setSelectedTab,
    handleOpenOrganizationNewPopup
  } = useContext(WorkspaceListContext);
  const workspaceBaseUrl = adminBaseURL("workspaces");

  const handleOnclickHeader = (tab, id) =>{
    if(id && id !== default_modes[0] && id !== default_modes[1]){
      window.history.replaceState(null,null, `/organizations/${id}`)
    } else {
      window.history.replaceState(null,null, "/")
    }
    setSelectedTab(tab)
    setSelectedOrganization(id)
  }

  const shared_organizations = organizations?.filter(org => org.organization_type === 'shared');
  const business_organizations = organizations?.filter(org => org.organization_type === 'business');

  return (
    <>
      <Accordion
        className="workspace_sidebar-accordion"
        activeKey={selected_tab}
      >
        <Accordion.Item eventKey="personal">
          <Accordion.Header
            className="personal mb-1"
            onClick={() => handleOnclickHeader("personal", "personal")}
          >
            My Workspace
          </Accordion.Header>
        </Accordion.Item>
        {
          shared_organizations?.length > 0 &&

          <Accordion.Item eventKey="shared">
            <Accordion.Header
              onClick={() => handleOnclickHeader("shared", "shared")}
            >
              Shared
            </Accordion.Header>
            <Accordion.Body>

              {
                shared_organizations?.map((data, index) => (
                  <div
                    className={`cursor-pointer mb-1 workspace_sidebar-accordion__workspace_link d-block text-truncate ${selected_organization === data.id && 'active'}`}
                    key={'shared_'+data.id}
                    onClick={() => handleOnclickHeader("shared", data.id)}
                  >
                    { data.name }
                  </div>
                ))
              }
            </Accordion.Body>
          </Accordion.Item>
        }
      </Accordion>


      <div
        className={'mb-4'}
      >
        {
          business_organizations?.length > 0 &&
          <>
            <hr/>

            <h3
              className="font-weight-black business_title"
            >
              <UpprovallPlusTitle beta/>
            </h3>

            <Accordion
              className="workspace_sidebar-accordion"
              activeKey={selected_organization?.toString()}
            >

              {
                business_organizations?.map((data, index) => (
                  <Accordion.Item
                    eventKey={data.id.toString()}
                    key={'business_'+data.id}
                    className="mb-1"
                  >
                    <Accordion.Header
                      onClick={() => handleOnclickHeader("business", data.id)}
                    >
                      { data.name }
                    </Accordion.Header>
                  </Accordion.Item>
                ))
              }
            </Accordion>
          </>
        }
      </div>

      <div className="d-grid gap-2">
        {
          can_create_organization ?
            <Button
              variant="add-business"
              onClick={() => handleOpenOrganizationNewPopup()}
            >
              <span className="mr-4">
                <CircleAddIcon color={"#fff"}/>
              </span>
              Add Business
            </Button>
          :

            <UpgradeButton />
        }

      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.organization.loading,
    organizations: state.organization.organizations,
    can_create_organization: state.organization.can_create_organization
  };
};

export default connect(mapStateToProps, { work_spaceActions })(Sidebar);
