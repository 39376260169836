import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {connect,  useDispatch, useSelector } from 'react-redux';
import { textInput } from '../../../shared/form-elements'
import textAreaAutoSize from "../../../shared/form-elements/textAreaAutoSize";
import { Button, Table, Card, Spinner, Image, Row, Col, SplitButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import { buttonClassForContentGroupAction } from '../../../utils/contentGroupUtils';
import { workspaceSizeAlert } from '../../../utils';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { commentActions, content_groupActions } from '../../../actions';
import { buildFormDataFromObject } from '../../../utils/commonUtils';
import { Capitalization } from '../../../utils/capitalization';
import { reduxForm, Field, reset, initialize, change, submit } from "redux-form";
import { validation } from "../../../shared/form-validations";
import { FieldUserTagSupport } from '../../../helpers/field_user_tag_support'
import { useInterval, ReplyContainer, ImageAttachment, FileAttachment, ChatMessageList } from '../../../components';
import { MessageBubble, UserDropdown, ImagePopup } from '../../../components';
import ShowIcon from '../../../images/icons/show.svg';
import HideIcon from '../../../images/icons/hide.svg';
import CheckIcon from "../../../images/icons/white/check.svg";
import RevisionIcon from "../../../images/icons/white/revision.svg";
import { contentConstants } from '../../../constants'
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation
} from "emoji-picker-react";
import autosize from "autosize";
import Smilly from "../../../images/smilly.png";
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';
import { ChatContext } from "../../../contexts/ChatContext";
import { dateTimeFormatter } from "../../../utils/";

function ChatMessage(props) {
  const dispatch = useDispatch();
  // const { workspace_id } = useParams();
  const {
    workspace_id,
    workspace,
    handleSubmit,
    initialComments,
    content_group,
    can_add,
    selected_content_id,
    setSelectedContentId,
    content_group_id,
    activeTab,
    setActiveTab,
    can_access_comment,
    can_access_team_comment,
    can_access_show_team_comment,
    can_access_accept_request_revision,
    can_access_submit_approval,
    can_access_accept_revision,
    setChatFocused,
    has_next_page,
    created_comment,
    currentWorkSpace
  } = props

  const addressComment = useSelector(state => state.comment.addressComment);
  const [hideInput, sethideInput] = useState(can_access_comment && can_access_comment ? true : false);
  const [showActivities, setShowActivities] = useState(false);
  const [sessionId, setSessionId] = useState(localStorage.getItem("set_content_id"));

  const inputRef = useRef()
  const tagUserDropdown = useRef()
  const scrollContainer = useRef();
  const [showEmojis, setShowEmojis] = useState();
  const [shouldBeFixed, setShouldBeFixed] = useState(false);
  const [comments, setComments] = useState([]);
  const [current_page, setCurrentPage] = useState(1);
  const PER_PAGE = 10;

  const Interval_MS = 15000;
  const bottomRef = useRef(null);
  const REPLY_ATTACHMENT_MAX_COUNT = 5;
  const REPLY_IMAGE_MAX_COUNT = 20;
  const {
    ATTACHMENT_MAX_SIZE,
    IMAGE_MAX_SIZE,
    BUSINESS_ATTACHMENT_MAX_SIZE,
    BUSINESS_IMAGE_MAX_SIZE
  } = contentConstants;

  const [tagSelectorText, setTagSelectorText] = useState("");
  const [isTagSelectorOpen, setTagSelectorOpen] = useState(false);

  const [shiftHeld, setShiftHeld] = useState(false);
  const [commentToReplyId, setCommentToReplyId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const commentRefs = useRef(new Array());
  const [highlightedCommentId, setHighlightedCommentId] = useState(null);
  const [approveDropdownSelectedOption, setApproveDropdownSelectedOption] = useState("Select");
  const [submitDropdownSelectedOption, setSubmitDropdownSelectedOption] = useState("Comment");
  const [requestDropdownSelectedOption, setRequestDropdownSelectedOption] = useState("Accept Request");
  const submitBtnRef = useRef(null);
  const [chatText, setChatText] = useState('');
  const [showApprovalDropdown, setShowApprovalDropdown] = useState(false);

  const getCommentRef = (element, id) => {
    let index = commentRefs.current.findIndex((el) => el.id == id)
    if (index == -1){
      commentRefs.current.push({ el: element, id: id})
    } else {
      if (commentRefs.current[index]){
          commentRefs.current[index] = { el: element, id: id}
      }
    }
  }

  /*
    Status Change Modal
  */
  const statuses = [
    'request_revision',
    'submit_for_approval',
    'void_submit',
    'approve_content_group',
    'accept_request',
    'reject_request'
  ]

  var reader = new FileReader();

  const uploadFileInputRef = useRef();

  const downHandler = ({key}) => {
    if (key === 'Shift') {
      setShiftHeld(true);
    }
  }

  const upHandler = ({key}) => {
    if (key === 'Shift') {
      setShiftHeld(false);
    }
  }

  const nextCommentPage = () => {
    let next_page = current_page + 1
    const load = true

    setCurrentPage(next_page)
    dispatch(commentActions.getComment(workspace_id, content_group_id, activeTab, load, showActivities, next_page, PER_PAGE, addComments));
  }

  useEffect(() => {
    let scroll_update = false;
    let comment_load = true;
    let initial_load = true;
    setCurrentPage(1)

    if(content_group_id){
      if(can_access_team_comment) {
        if(can_add){
          setActiveTab("internal_notes")
          dispatch(commentActions.getComment(workspace_id, content_group_id, "internal_notes", comment_load, showActivities, current_page, PER_PAGE, replaceComments));
        } else {
          setActiveTab("discussion")
          dispatch(commentActions.getComment(workspace_id, content_group_id, "discussion", comment_load, showActivities, current_page, PER_PAGE, replaceComments));
        }
      }else {
        setActiveTab("discussion")
        dispatch(commentActions.getComment(workspace_id, content_group_id, "discussion", comment_load, showActivities, current_page, PER_PAGE, replaceComments));
      }
    }

    if(can_access_comment){
      sethideInput(true)
    }else {
      sethideInput(false)
    }
  }, [content_group_id]);

  const addComments = (new_comments) => {
    if(new_comments){
      setComments(prevState => [...new_comments.reverse(), ...prevState])
    }
  }

  const replaceComments = (comments) => {
    if(comments){
      setComments(comments.reverse())
    }
  }

  const interval = useInterval(() => {
    if(content_group_id && !props.loading){
      const load = false
      dispatch(commentActions.getComment(workspace_id, content_group_id, activeTab, load, showActivities, 1, current_page * PER_PAGE, replaceComments));
    }
  }, Interval_MS);

  useEffect(() => {
    if(sessionId == content_group_id) {
      setShowActivities(true)
    }else {
      setShowActivities(false)
    }
  }, [sessionId]);

  useEffect(() => {
    if(created_comment){
      setComments(prevState => [...prevState, created_comment])
    }
  }, [created_comment]);

  useEffect(() => {
    if(created_comment){
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [comments]);

  const clearMessage = () => {
    inputRef.current.innerHTML = ""
  }

  const [isDisabled, setIsDisabled] = useState(false);

  const isSubmitDisabled = () => {
    return isDisabled || (inputRef.current.innerHTML.length == 0 && uploadedFiles.length == 0)
  }

  const processCommentWebSubmit = (event) => {
    const elem = submitBtnRef.current.getElementsByClassName('submit_btn')
    if(['Enter'].includes(event.key) && (event.target.innerHTML.length > 0 || uploadedFiles.length > 0) && !shiftHeld) {
      event.preventDefault()
      event.stopPropagation()
      if(elem){
        handleApprovalAction(elem[0].getAttribute('actiontext'))
      }
    } else if (['Enter'].includes(event.key) && shiftHeld) {
      document.execCommand('insertLineBreak');
      event.preventDefault();
      event.stopPropagation();
    } else if (['Enter'].includes(event.key) && !shiftHeld) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  const processCommentMobileSubmit = (e) => {
    if(inputRef.current.innerHTML.length > 0 || uploadedFiles.length > 0) {
      submitProcess({description: inputRef.current.innerHTML})
    }
  }

  const submitProcess = (values) => {
    if(!isDisabled && (uploadedFiles.length > 0 || values.description)){
      setIsDisabled(true)

      let objComment
      let formData = new FormData();

      if (addressComment) {
        objComment = addressComment
        objComment.internal = activeTab === "internal_notes"
      }

      let objValues = values

      objValues.internal = activeTab === "internal_notes"
      objValues.parent_id = commentToReplyId;
      objValues.attachments_attributes = uploadedFiles

      buildFormDataFromObject(formData, objValues, "comment");
      setSelectedContentId(null)
      setCommentToReplyId(null)
      setUploadedFiles([])
      setChatText('')
      clearMessage();
      uploadFileInputRef.current.value = '';
      dispatch(commentActions.createComment(formData, workspace_id, content_group_id, activeTab, current_page, PER_PAGE, setIsDisabled));
    }
  }

  const handleTab = (status) => {
    if (!!inputRef.current) {
      inputRef.current.innerHTML = ""
    }
    const load = false;
    const start_page = 1;

    setTagSelectorOpen(false)
    setCommentToReplyId(null)
    setCurrentPage(start_page);


    if(status === "discussion"){
      setActiveTab(status)
      dispatch(commentActions.getComment(workspace_id, content_group_id, status, load, showActivities, start_page, PER_PAGE, replaceComments));

      if(can_access_comment){
        sethideInput(true)
      }else {
        sethideInput(false)
      }
    }else {
      setActiveTab(status)
      dispatch(commentActions.getComment(workspace_id, content_group_id, status, load, showActivities, start_page, PER_PAGE, replaceComments));
      sethideInput(false)
    }
  }

  const handleChatToggle = () => {
    let chat_container = document.getElementById('chat_message-container');
    chat_container.classList.toggle('toggle');
  }

  const canAccessInternalNotes = () => {
    return can_add;
  };

  const canAccessPartnerNotes = () => {
    return true;
  };

  /*
    Popup Modal
  */
  const [imagePopup, setImagePopUp] = useState({
    show: false,
    image_url: null,
  });

  const renderParentComment = (comment) => {
    let parent = null;

    if (comment?.parent !== undefined){
      parent = comment.parent

      return <ReplyContainer data={parent} scrollToComment={scrollToComment}/>
    } else {
      if(comment.parent_id === null) return <></>;

      parent = comments?.find( (c) => c.id === comment.parent_id)

      if (parent === null || parent === undefined) return <></>;

      return <ReplyContainer data={parent} scrollToComment={scrollToComment} setCommentToReplyId={setCommentToReplyId}/>
    }
  }

  const scrollToComment = (comment_id) => {
    let element = commentRefs.current.find((el) => el.id == comment_id)
    if(element){
      element.el.scrollIntoView({ behavior: "smooth", block: "end" });
      higlightComment(element.id);
    } else {
      const callback = (result) => {
        replaceComments(result.data)
        setCurrentPage(result.new_page)

        scrollToComment(comment_id)
      }
      dispatch(commentActions.showComment(workspace_id, content_group_id, comment_id, showActivities, activeTab, current_page, PER_PAGE, callback))
    }
  }

  const higlightComment = (id) => {
    setHighlightedCommentId(id)
    setTimeout(function(){setHighlightedCommentId(null)},2000);
  }

  const selectCommentToReply = (comment_id, ref) => {
    if (comment_id) {
      let range = document.createRange();
      range.selectNodeContents(inputRef.current);
      range.collapse(false);
      let selection = window.getSelection();
      selection.removeAllRanges();

      const textLength = inputRef.current.innerHTML.length;
      selection.addRange(range);
      inputRef.current.focus();
    }

    setCommentToReplyId(comment_id)
  }

  const handleActive = () => {
    let showActivitiesTemp = !showActivities;
    setShowActivities(showActivitiesTemp)
    if(showActivities){
      localStorage.removeItem("set_content_id");
    }else {
      localStorage.setItem("set_content_id", content_group_id)
    }

    if(!props.loading){
      const load = true;
      dispatch(commentActions.getComment(workspace_id, content_group_id, activeTab, load, showActivitiesTemp, 1, current_page * PER_PAGE, replaceComments));
    }
  }

  const handleShowEmojis = () => {
    inputRef.current.focus()
    setShowEmojis(!showEmojis)
  }

  const updateTagSelector = (tag) => {
    setTagSelectorText(tag)
    setTagSelectorOpen(true)
    if(scrollContainer.current.scrollHeight > 300) {
      setShouldBeFixed(true)
    }else {
      setShouldBeFixed(false)
    }
  }

  const onEmojiClick = (e, {emoji}) => {
    var sel, range;
    sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      var el = document.createElement("div");
      el.innerHTML = e.emoji
      var frag = document.createDocumentFragment(), node, lastNode;
      while ( (node = el.firstChild) ) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);
      if (lastNode) {
        range = range.cloneRange();
        range.setStartAfter(lastNode);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
    setShowEmojis(!showEmojis)
  }

  const [searchTag, setSearchTag] = useState()
  const onKeyDown = (event) => {
    downHandler(event)

    if (isTagSelectorOpen && ['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
      event.preventDefault()
      event.stopPropagation()
      if (tagUserDropdown.current) {
        tagUserDropdown.current.handleKeyUp(event)
      }
    } else if (['Backspace'].includes(event.key)) {
      var selection = window.getSelection()
      if (!selection.isCollapsed || !selection.rangeCount) {
        return;
      }

      var curRange = selection.getRangeAt(0)
      if (curRange.commonAncestorContainer.nodeType == 3 && curRange.startOffset > 0) {
        return;
      }

      var range = document.createRange()
      if (selection.anchorNode != event.target) {
        range.selectNodeContents(event.target)
        range.setEndBefore(selection.anchorNode)
      } else if (selection.anchorOffset > 0) {
        range.setEnd(event.target, selection.anchorOffset)
      } else {
        return;
      }

      var previousNode = range.lastChild
      if (previousNode && previousNode.contentEditable == 'false') {
        range.deleteContents()
        event.preventDefault()
      }
    }
  }

  const commentPlaceholder = commentToReplyId == null ? 'Comment' : 'Reply';

  const handleUploadFiles = files => {
       const uploaded = [...uploadedFiles];
       let limitExceeded = false;
       const is_workspace_personal = currentWorkSpace?.personal;
       const image_size_limit = is_workspace_personal ? IMAGE_MAX_SIZE : BUSINESS_IMAGE_MAX_SIZE;
       const attachment_size_limit = is_workspace_personal ? ATTACHMENT_MAX_SIZE : BUSINESS_ATTACHMENT_MAX_SIZE;

       files.some((file) => {
           if (uploaded.findIndex((f) => f.file.name === file.file.name) === -1) {
               uploaded.push(file);

               if (file.file.name.match(/\.(jpg|jpeg|png|gif|webp)$/i)){
                 let uploaded_images = uploaded.filter((f) => f.file.name.match(/\.(jpg|jpeg|png|gif|webp)$/i));
                 if (uploaded_images.length === REPLY_IMAGE_MAX_COUNT) setFileLimit(true);
                 if (uploaded_images.length > REPLY_IMAGE_MAX_COUNT) {
                     alert(`You can only add a maximum of ${REPLY_IMAGE_MAX_COUNT} images`);
                     setFileLimit(false);
                     limitExceeded = true;
                 }
                 if (file.file.size > image_size_limit){
                   alert(`Maximum file size is ${is_workspace_personal ? '5' : '10'}MB.`);
                   setFileLimit(false);
                   limitExceeded = true;
                 }
               } else {
                 let uploaded_files = uploaded.filter((f) => !f.file.name.match(/\.(jpg|jpeg|png|gif|webp)$/i));
                 if (uploaded_files.length === REPLY_ATTACHMENT_MAX_COUNT) setFileLimit(true);
                 if (uploaded_files.length > REPLY_ATTACHMENT_MAX_COUNT) {
                     alert(`You can only add a maximum of ${REPLY_ATTACHMENT_MAX_COUNT} files`);
                     setFileLimit(false);
                     limitExceeded = true;
                 }

                 if (file.file.size > attachment_size_limit){
                   alert(`Maximum file size is ${is_workspace_personal ? '5' : '10'}MB.`);
                   setFileLimit(false);
                   limitExceeded = true;
                 }
               }
           }
       })

       if (!limitExceeded) {
        setUploadedFiles(uploaded);
        inputRef.current.focus();
       }

  }

  const handleFileEvent = (e) => {
    workspaceSizeAlert(currentWorkSpace);

    const chosenFiles = Array.prototype.slice.call(e.target.files)
    let mappedFiles = Array.prototype.slice.call(chosenFiles).map((f) => ({file: f}))
    handleUploadFiles(mappedFiles);
  }

  let chatContexts = {
    activeTab: activeTab,
    can_access_comment: can_access_comment
  }

  const removeHandleFileEvent =(file)=> {
    var array = [...uploadedFiles]; // make a separate copy of the array
    var index = array.indexOf(file)

    if (index !== -1) {
      array.splice(index, 1);
      setUploadedFiles(array);
    }
  }

  const renderButtonContentForApprovalAction = (action) => {
    switch(action) {
        case 'Accept Request':
          return <>
            Accept
          </>
        break;
        case 'Reject Request':
          return <>
            Reject
          </>
        break;
        case 'Approve':
          return <>
            Approve
          </>
          break;
        case 'Request Revisions':
          return <>
            Reject
          </>
          break;
        case 'Comment':
          return <>
            Comment
          </>
          break;
        case 'Void Submission':
          return <>
            Cancel
          </>
          break;
        default:
          return action;
    }
  }

  const isApprovalActionDisabled = (action) => {
    return content_group.stage.status === 'pending'
  }

  const handleApprovalAction = (action) => {
    if(inputRef.current.innerHTML.length === 0 && uploadedFiles.length === 0) return;
    if(action !== 'Select') setIsDisabled(true);

    let description = inputRef.current.innerHTML;
    let objValues = {};

    objValues.description = description;
    objValues.internal = false;
    objValues.is_notification = false;
    objValues.attachments_attributes = uploadedFiles;
    objValues.parent_id = commentToReplyId;

    switch(action) {
        case 'Accept Request':
        case 'Accept':
          objValues.event_type = 'accept_request';
          dispatch(
              content_groupActions.acceptRequestContentGroup(
                content_group_id,
                workspace_id,
                content_group?.stage?.approved_content_group_stage_id,
                activeTab,
                objValues,
                setIsDisabled
              )
          );
          break;
        case 'Reject Request':
        case 'Reject':
          objValues.event_type = 'reject_request';
          dispatch(
              content_groupActions.rejectRequestContentGroup(
                content_group_id,
                workspace_id,
                content_group?.stage?.approved_content_group_stage_id,
                activeTab,
                objValues,
                setIsDisabled
              )
          );
          break;
        case 'Request Revisions':
        case 'Disapprove':
          objValues.event_type = 'request_revision';
          dispatch(
            content_groupActions.requestRevisionContentGroup(
              content_group_id,
              workspace_id,
              content_group?.stage?.approved_content_group_stage_id,
              activeTab,
              objValues,
              setIsDisabled
            )
          );
          break;
        case 'Comment':
          processCommentMobileSubmit();
          break;
        case 'Approve':
          objValues.event_type = 'approve';
          dispatch(
            content_groupActions.approveContentGroup(
              content_group_id,
              workspace_id,
              content_group?.stage?.approved_content_group_stage_id,
              activeTab,
              objValues,
              setIsDisabled
            )
          );
          break;
        case 'Void Submission':
        case 'Cancel':
          objValues.event_type = 'void_submission';
          dispatch(
            content_groupActions.voidSubmitContentGroup(
              content_group_id,
              workspace_id,
              content_group?.stage?.approved_content_group_stage_id,
              activeTab,
              objValues,
              setIsDisabled
            )
          );
          break;
        case 'Submit':
          objValues.event_type = 'submit_for_approval';
          dispatch(
            content_groupActions.submitContentGroup(
              content_group_id,
              workspace_id,
              content_group?.stage?.id,
              activeTab,
              objValues,
              setIsDisabled
            )
          );
          break;
        case 'Select':
          setShowApprovalDropdown((prevState) => true);
          return
          break;
        default:
          setIsDisabled(false)
    }

    setSelectedContentId(null)
    setCommentToReplyId(null)
    setUploadedFiles([])
    setChatText('')
    clearMessage();
    uploadFileInputRef.current.value = '';
  }

  const handleCapitalization = (event) => {
    if(event.target.innerHTML.length > 1) return;

    let string = event.target.innerHTML;
    event.target.innerHTML = Capitalization(string);

    let range = document.createRange();
    range.selectNodeContents(event.target);
    range.collapse(false);
    let selection = window.getSelection();
    selection.removeAllRanges();

    const textLength = event.target.innerHTML.length;
    selection.addRange(range);
    event.target.focus();
  }

  const isApprovalButtonDisabled = () =>{
    return isDisabled || chatText.length === 0
  }

  const approvalButtonVariant = () =>{
    return isApprovalButtonDisabled() ? 'gray' : buttonClassForContentGroupAction(approveDropdownSelectedOption)
  }

  const chatOnFocus = (e) => {
    setChatFocused(true)
  }

  const chatOnBlur = (e) => {
    setChatFocused(false)
  }

  const renderCommentSubmitButton = () => {
    if (content_group?.require_approval_from_client && activeTab === 'discussion'){
      if(content_group?.stage?.submitted){
        if(can_access_submit_approval){
          if (content_group?.stage?.status === 'pending'){
            return <>
              <Dropdown
                as={ ButtonGroup }
                align="end"
                className={ requestDropdownSelectedOption }
                size={ 'sm' }
              >
                <Button
                  variant={ buttonClassForContentGroupAction(requestDropdownSelectedOption) }
                  onClick={ () => handleApprovalAction(requestDropdownSelectedOption)}
                  disabled={isDisabled}
                  actiontext={requestDropdownSelectedOption}
                  className="submit_btn"
                >
                  { renderButtonContentForApprovalAction(requestDropdownSelectedOption) }
                </Button>

                <Dropdown.Toggle split variant={ buttonClassForContentGroupAction(requestDropdownSelectedOption) } />

                <Dropdown.Menu className={ 'sm text-center' }>
                  { ['Accept Request', 'Reject Request'].map((action, index) => (
                    <>
                      { action !== requestDropdownSelectedOption &&
                        <Dropdown.Item
                          onClick={ () => setRequestDropdownSelectedOption(action) }
                          key={ 'request_action' + index }
                        >
                          { renderButtonContentForApprovalAction(action) }
                        </Dropdown.Item>
                      }
                    </>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </>
          } else {
            return <>
              <Dropdown
                as={ ButtonGroup }
                align="end"
                className={ submitDropdownSelectedOption }
                size={ 'sm' }
              >
                <Button
                  variant={ buttonClassForContentGroupAction(submitDropdownSelectedOption) }
                  onClick={ () => handleApprovalAction(submitDropdownSelectedOption)}
                  disabled={isDisabled}
                  className="submit_btn"
                  actiontext={submitDropdownSelectedOption}
                >
                  { renderButtonContentForApprovalAction(submitDropdownSelectedOption) }
                </Button>

                <Dropdown.Toggle split variant={ buttonClassForContentGroupAction(submitDropdownSelectedOption) } />

                <Dropdown.Menu className={ 'sm text-center' }>
                  { ['Comment', 'Void Submission'].map((action, index) => (
                    <>
                      { action !== submitDropdownSelectedOption &&
                        <Dropdown.Item
                          onClick={ () => setSubmitDropdownSelectedOption(action) }
                          key={ 'submission_action' + index }
                        >
                          { renderButtonContentForApprovalAction(action) }
                        </Dropdown.Item>
                      }
                    </>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </>
          }

        } else if (can_access_accept_request_revision) {
          if (content_group?.stage?.approved || content_group?.stage?.status === 'pending') {
            return <Button
                    variant="info"
                    type="submit"
                    disabled={isDisabled}
                    onClick={() => handleApprovalAction('Comment')}
                    actiontext={'Comment'}
                    size={"sm"}
                    className="submit_btn"
                   >
                      Comment
                    </Button>
          } else {
            return <>
              <Dropdown
                as={ ButtonGroup }
                align="end"
                className={ approveDropdownSelectedOption }
                size={ 'sm' }
                onToggle={(nextShow, meta) => setShowApprovalDropdown((prevState) => nextShow)}
                show={showApprovalDropdown}
              >
                <Button
                  variant={ approvalButtonVariant() }
                  onClick={ () => handleApprovalAction(approveDropdownSelectedOption)}
                  disabled={ isApprovalButtonDisabled() }
                  className="submit_btn"
                  actiontext={approveDropdownSelectedOption}
                >
                  { renderButtonContentForApprovalAction(approveDropdownSelectedOption) }
                </Button>

                <Dropdown.Toggle
                  split
                  variant={ approvalButtonVariant() }
                  disabled={ isApprovalButtonDisabled() }
                />

                <Dropdown.Menu
                  className={ 'sm text-center' }
                >
                  { ['Approve', 'Request Revisions', 'Select'].map((action, index) => (
                    <>
                      { action !== approveDropdownSelectedOption && action !== 'Select'  &&
                        <Dropdown.Item
                          onClick={ () => setApproveDropdownSelectedOption(action) }
                          disabled={ isApprovalActionDisabled(action) }
                          key={ 'request_action' + index }
                        >
                          { renderButtonContentForApprovalAction(action) }
                        </Dropdown.Item>
                      }
                    </>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </>
          }
        } else {
          return <Button
                  variant="info"
                  type="submit"
                  disabled={isDisabled}
                  onClick={() => handleApprovalAction('Comment')}
                  actiontext={'Comment'}
                  size={"sm"}
                  className="submit_btn"
                 >
                    Comment
                  </Button>
        }
      } else {
        if (can_access_submit_approval) {
          return <Button
                    variant="success"
                    type="submit"
                    disabled={isDisabled}
                    onClick={() => handleApprovalAction("Submit")}
                    actiontext={'Submit'}
                    size={"sm"}
                    className="submit_btn"
                  >
                    Submit
                  </Button>
        } else {
          return <Button
                  variant="info"
                  type="submit"
                  disabled={isDisabled}
                  onClick={() => handleApprovalAction('Comment')}
                  actiontext={'Comment'}
                  size={"sm"}
                  className="submit_btn"
                 >
                    Comment
                  </Button>
        }
      }
    } else {
      return <Button
              variant="info"
              type="submit"
              disabled={isDisabled}
              onClick={() => handleApprovalAction('Comment')}
              actiontext={'Comment'}
              size={"sm"}
              className="submit_btn"
             >
                Comment
              </Button>
    }
  }

  const show_team_tab = can_access_team_comment || can_access_show_team_comment;

  const showTeamTabButtons = () =>{
    return (canAccessPartnerNotes() && canAccessInternalNotes()) || show_team_tab
  }

  return (
    <ChatContext.Provider value={chatContexts}>
      <div className="content-group__chat-panel" ref={scrollContainer}>
        <div className="toggle-btn d-none">
          <Button onClick={() => handleChatToggle()} variant="primary" className="btn-sm mb-2 float-right">
            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
            <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
            <span><i className="fa fa-caret-down mr-2" aria-hidden="true"></i> Comments</span>
          </Button>
        </div>
        <div className="chatbtn-group">
          <div className="tabs">
            { showTeamTabButtons() &&
              <ul className="list-inline d-flex">
                { show_team_tab &&
                  <li className={activeTab === "internal_notes" ? 'active' : null} onClick={ () => handleTab("internal_notes")}>Team</li>
                }
                {
                  canAccessPartnerNotes() &&
                  <li className={activeTab === "discussion" ? 'active' : null} onClick={ () => handleTab("discussion")} style={{width: show_team_tab ? '50%' : '100%'}}>Approver</li>
                }
              </ul>
            }
          </div>
          <div className="content-group__chat-toggle-activities">
            <div className="cursor-pointer" onClick={() => handleActive()}>
              <img src={showActivities ? HideIcon : ShowIcon} height="12" className="button-icon" />
               Activities
            </div>
          </div>
        </div>

        { props.loading &&
            <div className="clearfix">
              <div className="spinner-container">
                <Spinner animation="border" />
              </div>
            </div>
        }

        <ChatMessageList
          comments={comments}
          activeTab={activeTab}
          bottomRef={bottomRef}
          showActivities={showActivities}
          getCommentRef={getCommentRef}
          highlightedCommentId={highlightedCommentId}
          selectCommentToReply={selectCommentToReply}
          commentToReplyId={commentToReplyId}
          workspace_id={workspace_id}
          setImagePopUp={setImagePopUp}
          renderParentComment={renderParentComment}
          showTeamTabButtons={showTeamTabButtons}
          has_next_page={has_next_page}
          nextPage={nextCommentPage}
          organization_id={currentWorkSpace?.organization_id}
        />

        {
          commentToReplyId &&
            <div
              className="selected-reply-container"
            >
              {
                renderParentComment({parent_id: commentToReplyId})
              }
            </div>
        }

        {can_access_comment && (
          <div
            className="cursor custom-editor"
            style={{backgroundColor: '#eee'}}
          >

            <div className="chatbox-container">
              <div
                contentEditable="true"
                id="contenteditable_div"
                className="contentedit_textarea vertical-scroll_thin no-bg mb-2"
                style={{ caretColor: '#787878'}}
                placeholder={ `${commentPlaceholder}...` }
                ref={inputRef}
                onFocus={chatOnFocus}
                onBlur={chatOnBlur}
                onKeyPress={processCommentWebSubmit}
                onKeyDown={onKeyDown}
                onKeyUp={upHandler}
                onPaste={(e) => {
                  e.preventDefault()
                  var text = e.clipboardData.getData('text/plain')
                  document.execCommand('insertText', false, text)
                }}
                onInput={(e) => {
                  var element = document.getElementById("contenteditable_div");
                  handleCapitalization(e);
                  var caretOffset = 0;
                  if (typeof window.getSelection != "undefined") {
                    var range = window.getSelection().getRangeAt(0);
                    var preCaretRange = range.cloneRange();
                    preCaretRange.selectNodeContents(element);
                    preCaretRange.setEnd(range.endContainer, range.endOffset);
                    caretOffset = preCaretRange.toString().length;
                  }
                  let pretext = e.currentTarget.textContent.substring(0, caretOffset);
                  let tag = pretext.match(/@([a-zA-Z0-9]+)$/)
                  if (tag && tag.length > 0) {
                    setSearchTag(tag[0])
                    updateTagSelector(tag[1])
                  } else {
                    setTagSelectorOpen(false);
                  }

                  if(pretext.length === 0 ) setShowApprovalDropdown((prevState) => false);
                  setChatText(pretext)
                }} />

                {
                  uploadedFiles.length > 0 &&
                  <>
                    <div className="image-attachment-container mb-2">
                      {
                        uploadedFiles && uploadedFiles.filter((file) => file.file.name.match(/\.(jpg|jpeg|png|gif|webp)$/i)).map((file, index) => (
                          <ImageAttachment
                            filename={ file.file.name }
                            thumbnail={ URL.createObjectURL(file.file) }
                            own={ true }
                            destroyable={ true }
                            key={ "file_"+index }
                            CustomHandleDelete={ (e) => removeHandleFileEvent(file) }
                          />
                        ))
                      }
                    </div>

                    {
                      uploadedFiles && uploadedFiles.filter((file) => !file.file.name.match(/\.(jpg|jpeg|png|gif|webp)$/i)).map((file, index) => (
                        <FileAttachment
                          filename={ file.file.name }
                          own={ true }
                          destroyable={ true }
                          key={ "image_"+index }
                          CustomHandleDelete={ (e) => removeHandleFileEvent(file) }
                        />
                      ))
                    }
                  </>
                }

                <div className="d-flex justify-content-between">
                  <div>
                    <div
                      onClick={ () => handleShowEmojis()}
                      style={{cursor: 'pointer'}}
                      className="d-inline-block"
                    >
                      <img
                        src={Smilly}
                        height="20"
                        style={{marginRight: 5}}
                      />
                    </div>

                    <label htmlFor="file-upload" className="btn mt-1">
                      <i className="fa fa-paperclip" aria-hidden="true"></i>
                    </label>
                    <input id="file-upload" type="file" multiple hidden ref={uploadFileInputRef} onChange={handleFileEvent}/>
                  </div>

                  <div>
                    <div
                      ref={submitBtnRef}
                    >
                      { renderCommentSubmitButton() }
                    </div>
                  </div>
                </div>
            </div>
          </div>
        )}
        { isTagSelectorOpen &&
          <UserDropdown
            ref={ tagUserDropdown }
            shouldBeFixed={shouldBeFixed}
            filterText={ tagSelectorText }
            handleSelectUser={ (event, user) => {
              if (user){
                FieldUserTagSupport.user_selected(inputRef.current, user, searchTag)
              } else {
                processCommentWebSubmit(event)
              }

              setTagSelectorOpen(false);
              setTagSelectorText("");
              setSearchTag("")
            }}
            users={ activeTab == "discussion" ? workspace.users.partner_coordination_objects : workspace.users.internal_coordination_objects }
          />
        }
        { showEmojis &&
          <div className="emoji_picker">
            <EmojiPicker
              onEmojiClick={onEmojiClick}
              autoFocusSearch={false}
              searchDisabled
            />
          </div>
        }

        {
          imagePopup.show &&
          <ImagePopup
            handleClose={ () => setImagePopUp({show: false, image_url: null}) }
            image_url={ imagePopup.image_url }
          />
        }
      </div>
    </ChatContext.Provider>
  );
}

ChatMessage = reduxForm({
  form: "commentForm",
  validate: validation,
  enableReinitialize: true
})(ChatMessage);

const mapStateToProps = (state, props) => {
  const addressComment = state.comment.addressComment
  return {
    loading: state.comment.loading,
    currentWorkSpace: state.content.currentWorkSpace,
    initialComments: state.comment.comments,
    can_access_accept_revision: state.content.can_access_accept_revision,
    can_access_accept_request_revision: state.content.can_access_accept_request_revision,
    can_access_team_comment: state.content.can_access_team_comment,
    can_access_show_team_comment: state.content.can_access_show_team_comment,
    content_group: state.content.currentContentGroup,
    workspace: state.content.currentContentGroupWorkspaceForChat,
    can_access_comment: state.comment.can_access_comment,
    can_access_submit_approval: state.content.can_access_submit_approval,
    has_next_page: state.comment.has_next_page,
    created_comment: state.comment.created_comment,
    can_add: state.content.can_add,
    initialValues: {
      content_id: props && props.selected_content_id,
    }
  };
};



export default connect(mapStateToProps, { commentActions })(ChatMessage);
