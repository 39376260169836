import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { userActions, alertActions } from '../../../actions';
import { validation } from '../../../shared/form-validations';
import { textInput, PasswordInput } from '../../../shared/form-elements'
import { reduxForm, Field, reset } from "redux-form";
import { Button } from 'react-bootstrap';
import { LoginHeader } from '../LayoutPage/loginHeader';
import { Image, isLoggedIn } from '../../../utils';
import { AlertText } from '../../../components/';
import { history } from '../../../helpers';
import { userConstants } from '../../../constants';
import { jwtDecode } from "jwt-decode";
import {dataCredential} from "../../../types/dataCredential";

function Login(props) {
  const isUserLoggedIn = localStorage.getItem('user') ? true : false;
  const { handleSubmit} = props
  const dispatch = useDispatch();
  const location = useLocation();
  const [ show_links, setShowLinks ] = useState(false);
  const [ login_error, setLoginError ] = useState();
  // reset login status
  useEffect(() => {
    if(isLoggedIn()){
      history.push('/');
      dispatch(alertActions.error(userConstants.LOGIN_ALREADY));
    }else{
      dispatch(userActions.logout());
    }
  }, []);

  const loginSubmit = (values) => {
    const errorCallback = (message) => {
      setLoginError(message)
    }

    setLoginError('');
    dispatch(userActions.login(values, errorCallback));
    dispatch(reset('loginform'));
  }

  const onLoginSucess = (response) => {
    console.log(response)
    if(response) {
      const USER_CREDENTIAL: dataCredential = jwtDecode(response.credential)
      const { email, given_name, family_name } = USER_CREDENTIAL;
      const user = { email, given_name, family_name }
      dispatch(userActions.responseGoogle(user));
    }
  }

  const onLoginError = () => {
    setLoginError('Authentication Failed.')
  }

  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => onLoginSucess(codeResponse),
    onError: codeResponse => onLoginError()
  });

  if(isUserLoggedIn){
    return <div className="page_loading">Loading..</div>
  }

  return (
    <main className="login-page full_height" style={{ backgroundImage: 'url("' + Image.Layout.LoginBackground.default + '")' }}>
      <div className="login-page__box d-flex justify-content-center align-items-center col-12 col-md-6 offset-md-6">
        <div
          className="col-md-8"
        >
          <LoginHeader />

          {
            show_links ?
              <>
                <h3 className="font-weight-bold text-center">Can't log in?</h3>
                <ul className="cant_login-links text-center list-unstyled mx-5">
                  <li
                    className="py-4"
                  >
                    <Link to="/password/new">Forgot Password</Link>
                  </li>
                  <li
                    className="py-4"
                  >
                    <Link to="/confirmation/resend">Resend Confirmation Email</Link>
                  </li>
                  <li
                    className="py-4"
                  >
                    <Link to="/users/unlock/request">Request Unlock Email</Link>
                  </li>
                </ul>
              </>
            :
              <>
                <h3 className="font-weight-bold text-center mb-4">Log In</h3>
                <form
                  onSubmit={handleSubmit(loginSubmit)}
                  className={'login-page__form'}
                >
                  <div className="mb-2">
                    <Field name="email" type="email" component={textInput} label="Email Address"/>
                  </div>
                  <div className="mb-3 login-password-div">
                    <Field name="password" type="password" component={PasswordInput} label="Password"/>
                  </div>

                  <div className="mb-4 my-3">
                    <div className="my-2 mt-4">
                      <AlertText />
                    </div>
                    <Button variant="brand" type="submit" className="full-width">Log in</Button>
                  </div>
                </form>
                <div className="google-container text-center mt-2">
                  <GoogleLogin
                    onSuccess={credentialResponse => {
                      onLoginSucess(credentialResponse)
                    }}
                    onError={() => {
                      onLoginError()
                    }}
                  />
                </div>

                <hr/>
              </>
          }
          <div className="mt-4">
            <ul className="login-form__button-list">
              {
                show_links ?
                  <li>
                    <div
                      className={'cursor-pointer mb-2'}
                      onClick={() => setShowLinks(false)}
                    >
                      Back to log in
                    </div>
                  </li>
                :
                  <li>
                    <div
                      className={'cursor-pointer mb-2'}
                      onClick={() => setShowLinks(true)}
                    >
                      Can't log in?
                    </div>
                  </li>
              }

              <li><Link to="/register">Create an account</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
}

Login =  reduxForm({
  form: 'loginform',
  validate: validation,
  touchOnBlur: false
})(Login);


const mapStateToProps = (state) => {
  return { user: state.authentication.user };
};

export default connect(mapStateToProps, { userActions })(Login);
