import { useEffect, useState, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import { contentActions } from '../../../../actions';
import { useDispatch, connect } from 'react-redux';
import { buildFormDataFromObject } from '../../../../utils/commonUtils';
import { HiddenContentImageUploader, CircleAddIcon } from '../../../../components';

function AttachmentsCarousel(props) {
  const {
    attachments,
    workspace_id,
    content_group_id,
    updated_at,
    isDragDisabled,
    selected_id,
    galleryRef,
    selectedAttachmentId,
    setSelectedAttachmentId,
    can_access_content_create,
    content_popup_notification,
    currentWorkSpace
  } = props;

  const dispatch = useDispatch();
  const [attachment_list, setAttachmentList] = useState(attachments);
  const attachmentRefs = useRef(new Array());
  const dropzoneRef = useRef(null);

  useEffect(() => {
    setAttachmentList(attachments)

    let index = attachments.findIndex(obj => obj.id === selected_id);

    if(index !== -1){
      galleryRef.current.slideToIndex(index)
    }
  }, [attachments])

  useEffect(() => {
    galleryRef.current.slideToIndex(0)
  }, [attachments.length])

  const grid = 8;

  const getItemStyle = (snapshot, provided) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: '5px',
      margin: `0 ${grid}px 0 0`,

      // styles we need to apply on draggables
      ...provided.draggableProps.style,
    }
  };


  const onDragUpdate = (e) => {
    if (attachmentRefs.current?.length > 0){
      let currentAttachment = attachmentRefs.current.find((el) => el.id == e.draggableId)

      if (currentAttachment && e.destination) {
        dropzoneRef.current.style.width = `${currentAttachment.el.offsetWidth - grid}px`;
        dropzoneRef.current.style.left =  `${((currentAttachment.el.offsetWidth + grid) * e.destination.index)}px`;
      }
    }
  }

  const dropzoneStyle = () => ({
    marginLeft: `${grid + 5}px`
  });

  const getListStyle = isDraggingOver => ({
    position: 'relative',
    padding: grid
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list

    if (!result.destination) {
      return;
    }

    const items = reorder(
      attachment_list,
      result.source.index,
      result.destination.index
    );

    setAttachmentList(items)
    submitPosition(items)
  }

  const submitPosition = (newRow) => {
    const hash = newRow.map((row) => ({
      id: row.id
    }))
    let formData = new FormData();
    buildFormDataFromObject(formData, hash, "sort");
    dispatch(contentActions.sortContentAttachment(formData, workspace_id, content_group_id));
  }

  const selectAttachment = (id, index) => {
    if (selectedAttachmentId == id) {
      setSelectedAttachmentId(0)
    } else {
      setSelectedAttachmentId(id)
      galleryRef.current.slideToIndex(index)
    }
  }

  const getAttachmentRef = (element, id, ref) => {
    let index = attachmentRefs.current.findIndex((el) => el.id == id)
    if (index == -1){
      attachmentRefs.current.push({ el: element, id: id})
    } else {
      if (attachmentRefs.current[index]){
          attachmentRefs.current[index] = { el: element, id: id}
      }
    }

    return ref(element)
  }

  const addFileInput = useRef(null);
  const handleFileAddClick = event => addFileInput.current.click();

  return (
    <div
      className="attachment_list-container"
    >
      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
              className={`${snapshot.isDraggingOver ? 'dragged' : 'not-dragged'} attachment_list-carousel mx-2`}
            >
              {attachment_list.map((item, index) => (
                <Draggable
                  key={'attachment_thumbnail_'+item.id}
                  draggableId={item.id.toString()}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(dragProvided, dragSnapshot) => (
                    <div
                      ref={(el) => getAttachmentRef(el, item.id, dragProvided.innerRef)}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      style={getItemStyle(dragSnapshot, dragProvided)}
                      onClick={(e) => selectAttachment(item.id, index)}
                      className={
                        `attachment_list-carousel-item text-center d-flex ${selectedAttachmentId === item.id ? 'active' : null}`
                      }
                    >
                      <div
                        style={{
                          width: '60px',
                          height: '60px',
                          justifyContent: 'center',
                          backgroundSize: 'cover',
                          backgroundPosition: '50%',
                          borderRadius: '5px',
                          overflow: 'hidden'
                        }}
                      >
                        <Image
                          src={item.thumbnail ? item.thumbnail.url : item.content_attachment}
                          style={{height: 'inherit', width: 'inherit', objectFit: 'cover'}}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}


              { can_access_content_create &&
                <Draggable
                  key={'add_attachment'+attachment_list.length}
                  draggableId={attachment_list.length.toString()}
                  index={attachment_list.length}
                  isDragDisabled={true}
                >
                  {(dragProvided, dragSnapshot) => (
                    <div
                      ref={dragProvided.innerRef}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      className="d-flex align-items-center"
                    >
                    <HiddenContentImageUploader
                      fileInputRef={ addFileInput }
                      workspace={currentWorkSpace}
                      workspace_id={ workspace_id }
                      content_group_id={ content_group_id }
                      content_popup_notification={ content_popup_notification }
                    />
                    <div
                      className="content-group__toolbar-buttons add-content"
                    >
                      <Button
                        onClick={handleFileAddClick}
                        className="btn btn-default custom-tooltip add-file mx-0 d-flex"
                        data-toggle="tooltip"
                        title="Add File"
                        variant=""
                      >
                        <CircleAddIcon />
                      </Button>
                    </div>
                    </div>
                  )}
                </Draggable>
              }
              {provided.placeholder}

              <div className="dropzone-line" ref={dropzoneRef} style={dropzoneStyle()}/>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentWorkSpace: state.content.currentWorkSpace
  };
};

export default connect(mapStateToProps, {})(AttachmentsCarousel);
