import { useState, useRef, useEffect, useCallback } from 'react';

function ContentGroupInlineEdit(props) {
  const { content_group_data, can_access_content_update,  } = props
  const [ editMode, setEditMode ] = useState(false);
  const [ content, setContent] = useState(content_group_data);
  const [ width, setWidth] = useState(0);
  const [ height, setHeight ]= useState(0);
  const span = useRef();
  const input = useRef();

  useEffect(() => {
   setContent(content_group_data)
  }, [content_group_data]);

  useEffect(() => {
    if(span?.current?.offsetWidth){
      setWidth(span?.current?.offsetWidth + 14)
    }
  }, [span?.current?.offsetWidth]);

  useEffect(() => {
    if(input?.current?.offsetHeight){
      setHeight(input?.current?.offsetHeight + 12)
    }
  }, [input?.current?.offsetHeight]);

  const handleEnableEditMode = useCallback(() => {
    setEditMode(true)
    setTimeout(() => {
      input.current.focus()
      input.current.select()
    }, 50)
  }, [input])

  const onKeyDown = (event) => {
    if (event.key === "Escape") {
      setContent(content_group_data);
      setEditMode(false);
    }

    if (event.key === "Enter") {
      event.target.blur();
    }

    event.stopPropagation();
  }

  const setFocused = () => { };

  const handleInputOnChange = (event) => setContent(event.target.value)

  const handleOnBlurInput = (e) => {
    if(e.target.value != '' && e.target.value != content_group_data) {
      props.handleInlineEdit(setFocused, {name: e.target.value})
      setEditMode(false)
    }else {
      setContent(content_group_data)
      setEditMode(false)
    }
  }

  const onInput = (target) => {
    if (target?.scrollHeight > 33) {
      target.style.height = "5px";
      target.style.height = target.scrollHeight + 5 + "px";
    }
  };

  useEffect(() => {
    if(input){
      onInput(input.current);
    }
  }, [onInput, input]);

  return (
    <>
    {
      can_access_content_update ?
        <textarea
          type="text"
          ref={input}
          value={content}
          onKeyDown={onKeyDown}
          onChange={handleInputOnChange}
          onInput={(event) => onInput(event.target)}
          placeholder="Edit title"
          onBlur={handleOnBlurInput}
          style={{
            padding: '5px'
          }}
          className="inline_edit_input d-block disableable vertical-scroll_thin hidden-scroll h4 w-100 my-1 font-weight-bold"
        />
      :
        <h1
          className="h4 w-100 mb-0 font-weight-bold position-relative"
          style={
            { maxWidth: '600px' }
          }
        >
          <div
            className={""}
          >
            {content}
          </div>
        </h1>
    }

    </>
  );
}

export { ContentGroupInlineEdit };
