import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../../../actions/notification.actions.js';
import { content_groupActions, work_spaceActions } from '../../../actions';
import { Table, Button, Spinner } from 'react-bootstrap';
import ContentList from "../ContentPage/list";
import ShowIcon from '../../../images/icons/show.svg';
import HideIcon from '../../../images/icons/hide.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import NotificationRow from './notificationRow'

function NotificationsPage(props) {
  const dispatch = useDispatch();
  const { notifications, can_access_content, can_access_edit_title } = props
  const [ notificaion_type, set_notification_type] = useState("un_read");
  const [ filter_notifications, set_filter_notifications] = useState(notifications);
  const [ contentGroupPopUp, setContentGroupPopUp] = useState({id: null, show: false });
  const [ workspaceId, setWorkspaceId] = useState();
  const [ filter_activites, set_filter_activites] = useState(false);
  const [ hasNextPage, setHasNextPage ] = useState(false);
  const [ readNotification, setReadNotification ] = useState(false);
  const work_space = useSelector(state => state.work_space.currentWorkSpace);

  useEffect(() => {
    loadNotifications(null)
  }, [filter_activites]);

  const loadNextPage = () => {
    let lastNotificationId = notifications[notifications.length - 1].id
    loadNotifications(lastNotificationId)
  }

  const loadNotifications = (lastNotificationId) => {
    let success_callback = (data) => {
      if (lastNotificationId) {
        set_filter_notifications(filter_notifications.concat(data.data))
        setHasNextPage(data.data.length > 0)
      } else {
        set_filter_notifications(data.data)
        setHasNextPage(data.has_next_page)
      }
    }

    dispatch(notificationActions.getPage(filter_activites, lastNotificationId, success_callback))
  }

  const handleRead = (notificationId) => {
    dispatch(notificationActions.read(notificationId));
  }

  const handleUnRead = (notificationId) => {
    dispatch(notificationActions.unRead(notificationId));
  }

  const handleReadAll = () => {
   dispatch(notificationActions.readAll(() => loadNotifications(null)));
  }

  const onSwitchNotificaton = () => {
    set_filter_activites(!filter_activites)
  }

  const handleCloseContentGroupPopUp = () => {
    setContentGroupPopUp({id: null, show: false})
  }

  const handleInlineEdit = (handleOnBlur, values, content_group_id) => {
    dispatch(content_groupActions.inlineEditContentGroup(handleOnBlur, values, content_group_id, workspaceId));
  }

  return (
    <>
      <div className="container layout__container-wrapper layout__container-wrapper--notifications">
        <div className="col-sm-12 pt-3">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item"><Link to="/notifications">Notifications</Link></li>
          </ol>
        </div>

        <h2 className="mb-2">Notifications</h2>

      <div className="d-flex align-items-center justify-content-between mb-1">
        <div className="d-flex all_notifiction_row align-items-center">
          <Link to="#" onClick={handleReadAll}>Mark All Read</Link>
          <span onClick={() => onSwitchNotificaton(notificaion_type)} className="px-1 cursor-pointer">
            {filter_activites ? <img src={HideIcon} height="12" className="button-icon" /> : <img src={ShowIcon} height="12" className="button-icon" />}
          </span>
        </div>
      </div>
      <InfiniteScroll
        dataLength={filter_notifications?.length}
        hasMore={hasNextPage}
        loader={
          <div className="clearfix">
            <div className="spinner-container">
              <Spinner animation="border" />
            </div>
          </div>
        }
        next={loadNextPage}
        scrollableTarget="mainContainer"
        endMessage={
          <p className="text-center">
              <b>There are no more notifications.</b>
          </p>
        }
      >
        <Table className="table table-bordered table-light" responsive>
          <tbody>
           { filter_notifications && filter_notifications.length > 0
              ?
                filter_notifications
                .map((data, index) => (
                  <NotificationRow
                    mode="notification"
                    data={ data }
                    key={ "notification_list_"+data.id }
                    setWorkspaceId={ setWorkspaceId }
                    setContentGroupPopUp={ setContentGroupPopUp }
                    readable={true}
                  />
                ))
              :
               <tr>
                  <td>
                    <div className="d-flex justify-content-center py-4">
                      There are no notifications.
                    </div>
                  </td>
               </tr>
            }
          </tbody>
        </Table>
      </InfiniteScroll>
      </div>
      { contentGroupPopUp.show &&
          <ContentList
            content_group_id={contentGroupPopUp.id}
            workspace_id={workspaceId}
            updated_at={work_space && work_space.updated_at}
            handleCloseContentGroupPopUp={handleCloseContentGroupPopUp}
            handleInlineEdit={handleInlineEdit}
            setContentGroupPopUp={setContentGroupPopUp}
            can_access_content={can_access_content}
            can_access_edit_title={can_access_edit_title}
            content_popup_notification="show_content_notification"
          />
      }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.workspaceUser.loading,
    notifications: state.notification.notifications,
    can_access_content: state.notification.can_access_content,
    can_access_edit_title: state.notification.can_access_edit_title
  };
};

export default connect(mapStateToProps, { notificationActions })(NotificationsPage);
