import { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { NavDropdown, Dropdown } from 'react-bootstrap';
import CheckIcon from '../images/icons/check.svg';

function StageLabel(props) {
  const {
          name,
          can_access_edit_title,
          stages,
          stageParentRef,
          scrollable,
          alignment
        } = props;
  const selectRef = useRef();
  const dropdownRef = useRef();
  const [dropdownStyle, setDropdownStyle] = useState({ bottom: 0, width: 0 });
  let parent_bounds = stageParentRef?.current?.getBoundingClientRect();

  const handleSelect = (e, stageId) => {
    props.handleInlineEdit(function() {}, { stage_id: stageId })
  }

  const textAlignment = () =>{
    if(alignment === undefined) return 'text-center';

    return "text-"+alignment;
  }

  useEffect(() => {
    if (parent_bounds){
      setDropdownStyle({width: parent_bounds?.width, bottom: parent_bounds?.bottom + 20 })
    }
  }, [stageParentRef?.current, parent_bounds?.bottom, parent_bounds?.width, scrollable]);

  const getStyle = () => {

    if (scrollable) {
      return {
        width: `${dropdownStyle.width}px`,
        maxHeight: `calc(100vh - 480px)`,
        overflowY: 'auto'
      }
    } else {
      return {
        width: `${parent_bounds?.width}px`
      }
    }
  }

  return (
    <>
      { can_access_edit_title ?
        <div ref={selectRef}>
          <Dropdown className="content__stage-dropdown p-1 dropdown--with-check" ref={dropdownRef}>
            <Dropdown.Toggle variant="" className={`${textAlignment()} p-0 text-truncate w-100`} id="dropdown-basic">
              {name}
            </Dropdown.Toggle>

            <Dropdown.Menu style={getStyle()} className="stage-list__dropdown vertical-scroll_thin">
              { stages && stages.map((opt, index) => (
                  <Dropdown.Item key={opt.id} onClick={(e) => handleSelect(e, opt.id) } className={'text-truncate'}>
                    { opt.name }

                    { opt.name == name &&
                      <span className="dropdown__selected--with-check">
                        <img src={CheckIcon} alt="Selected" />
                      </span>
                    }
                  </Dropdown.Item>
                ))
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
      :
        <div className="content-group__readonly-stage text-truncate">
          {name}
        </div>
      }
    </>
  );
}

export { StageLabel };
