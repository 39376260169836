import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {connect,  useDispatch, useSelector } from 'react-redux';
import { stageActions, content_groupActions, assigned_userActions, alertActions, contentLabelActions, work_spaceActions } from '../../../actions';
import { Button, Row, Col, Dropdown, ButtonGroup, Image } from 'react-bootstrap';
import {
  Popup,
  BasicSpinner,
  ContentLabel,
  StageInlineEdit,
  DateTimeInlineEdit,
  StageLabel,
  EditStageDropdown,
  PerPageContentGroup
} from '../../../components';
import NewContentGroupSingleFieldForm from './newSingleFieldForm';
import AssignedUserNew from "../AssignedUserPage/new";
import { CircleAddIcon } from "../../../components";
import DragIcon from "../../../images/drag.png";
import LoadiGif from "../../../images/loading_image.gif";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { useMediaQuery } from 'react-responsive'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ParticipantField from "./sections/participantField";
import LabelField from "./sections/labelField";
import { useIsVisible } from '../../../utils/useIsVisible';
import { WorkspaceShowContext } from "../../../contexts/WorkspaceShowContext";
import { dateTimeFormatter } from "../../../utils/"

function ContentGroupList(props) {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const dispatch = useDispatch();
  const {
    index,
    rows,
    stage_id,
    workspace_id,
    stage,
    stage_name,
    handleOpenAssignedUserPopup,
    can_access_edit_title,
    can_access_assigned_user,
    can_access_move_content_list,
    can_access_show_content_details,
    can_access_add_change_date,
    handleInlineEdit,
    can_access_approval_dots,
    can_access_edit_label,
    allowed_stages,
    can_access_move_stage_board,
    addContentGroupToStage,
    loadNextPage,
    stages_to_update,
    updated_at,
    removeContentGroupToStage,
    currentSearchText,
    updated_content_group
  } = props

  const perPage = PerPageContentGroup;
  const [page, setPage] = useState(rows?.page);
  const [total_pages, setTotalPages] = useState(0);
  const [content_groups, setContentGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddContentGroupField, setShowAddContentGroupField] = useState(false);
  const [can_crud_content_group, setCanCrudContentGroup] = useState(false);
  const addContentGroupRowRef = useRef();
  const stageRef = useRef();
  const isVisible = useIsVisible(stageRef);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [editModeCatcher, setEditModeCatcher] = useState(false);
  const loading_ref = useRef(loading);

  const {
    can_access_stage,
    can_access_content,
    can_access_sort_stage,
    resetStage,
    replaceContentGroupToStage,
    handleSortByDueDateStage,
    handleOpenContentGroupPopUp,
    handleShowDeleteStagePopup,
    refreshContentGroupToStage,
    loadTracker,
    query
  } = useContext(WorkspaceShowContext);

  const addContentGroups = (result) => {
    const reset_content_group = false;
    addContentGroupToStage(stage, result.content_groups, result.total_pages, result.has_next_page, reset_content_group)
  }

  const resetContentGroup = (result) => {
    const reset_content_group = true;
    addContentGroupToStage(stage, result.content_groups, result.total_pages, result.has_next_page, reset_content_group)
  }

  const loadNextPageContentGroups = (result) => {
    const reset_content_group = true;
    addContentGroupToStage(stage, result.content_groups, result.total_pages, result.has_next_page, reset_content_group)
  }

  const refreshListPaginate = (result) => {
    refreshContentGroupToStage(stage, result.content_groups)
  }

  useEffect(() => {
    if(isVisible && initialLoaded && loadTracker && !loading_ref.current){
      console.log(rows)
      console.log('List Load Tracker')
      dispatch(stageActions.refreshListPaginate(workspace_id, stage_id, refreshListPaginate, 1, rows?.page * perPage, currentSearchText));
    }
 }, [loadTracker]);


   useEffect(() => {
     loading_ref.current = loading
   }, [loading]);

  useEffect(() => {
    if(isVisible && !initialLoaded && rows?.page === 1){
      const search = query.get('search');
      dispatch(stageActions.listPaginate(workspace_id, stage_id, resetContentGroup, setLoading, 1, perPage, search));
      setInitialLoaded(true);
    } else if(isVisible && initialLoaded){
      console.log("List isVisible refreshListPaginate")
      dispatch(stageActions.refreshListPaginate(workspace_id, stage_id, refreshListPaginate, 1, rows?.page * perPage, currentSearchText));
    }
  }, [isVisible]);

  useEffect(() => {
    if(props?.created_content_group && stage_id == props?.created_content_group.stage_id){
      addContentGroupToStage(stage, [props.created_content_group], stage.total_pages)
    }
  }, [props?.created_content_group]);

  useEffect(() => {
    if(props?.destroyed_content_group && stage_id == props?.destroyed_content_group.stage_id){
      removeContentGroupToStage(stage, props.destroyed_content_group)
    }
  }, [props?.destroyed_content_group]);

  /*
    For Pagination
  */
  useEffect(() => {
    if(rows?.page && isVisible && rows?.page > 1){
      dispatch(stageActions.listPaginate(workspace_id, stage_id, addContentGroups, setLoading, rows?.page, perPage, currentSearchText));
    }
  }, [rows?.page]);

  useEffect(() => {
    if(loading_ref.current) return;

    if (currentSearchText !== '' || !isVisible) {
      setInitialLoaded(false)
    } else {
      setInitialLoaded(true)
    }
    if(rows?.page && isVisible){
      resetStage(rows);
      dispatch(stageActions.listPaginate(workspace_id, stage_id, resetContentGroup, setLoading, 1, perPage, currentSearchText));
    }
  }, [currentSearchText]);

  useEffect(() => {
    if (updated_content_group) {
      let current_content_group_index = rows?.items.findIndex(r => r.id === updated_content_group.id)

      if (current_content_group_index !== -1) {
        if (rows?.id != updated_content_group.stage_id) {
          removeContentGroupToStage(rows, updated_content_group)
        } else {
          replaceContentGroupToStage(rows, updated_content_group)
        }
      } else {
        if (rows?.id == updated_content_group.stage_id) {
          addContentGroupToStage(rows, [updated_content_group])
        }
      }
    }
  }, [updated_content_group]);

  useEffect(() => {
    if (rows && stages_to_update){
      let reset_this_stage = stages_to_update.map((e) => e.id).includes(rows.id)

      if(reset_this_stage){
        resetStage(rows);

        if(isVisible){
          dispatch(stageActions.listPaginate(workspace_id, rows?.id, resetContentGroup, setLoading, 1, perPage, currentSearchText));
        }

        setInitialLoaded(false);
      }
    }
  }, [stages_to_update]);

  /* Filtered Content Group */

  const filter_options =[
    "all",
    "active",
    "archived"
  ];

  /*
  const [filter, setFilter] = useState(filter_options[0]);
  let filtered_content_groups = []
  let active_content_groups = content_groups && content_groups.length > 0 && content_groups.filter((content_group) => content_group.archived == false);
  let archived_content_groups = content_groups && content_groups.length > 0 && content_groups.filter((content_group) => content_group.archived == true);

  switch(filter){
    case "active":
      filtered_content_groups = active_content_groups;
      break;

    case "archived":
      filtered_content_groups = archived_content_groups;
      break;

    default:
      filtered_content_groups = content_groups;
  }
  */

  const handleInlineStageEdit = (handleOnBlur, values, workspace_id, stage_id) => {
    if(can_access_edit_title) {
      dispatch(stageActions.inlineEditStage(handleOnBlur, values, workspace_id, stage_id));
    }else {
      dispatch(alertActions.error("You are not authorized to access this page"));
    }
  }

  const handleCloseNewForm = () => {
    setShowAddContentGroupField(false);
  };

  const handleCreateContentGroupSuccess = (id) => {
    setShowAddContentGroupField(false)
  };

  const handleNewContentGroupSubmit = (values) => {
    dispatch(content_groupActions.createContentGroup(values, workspace_id, handleCreateContentGroupSuccess));
  }

  const stageSettingStyle = () => {

    if (can_access_approval_dots){
      let newWidth = 0;

      if (stage.require_approval_from_client) {
        newWidth = newWidth + 55
      }

      if (stage.visible_to_client) {
        newWidth = newWidth + 55
      }

      if (newWidth === 0) return {};

      return { minWidth: newWidth > 70 ? 70 : newWidth }
    } else {
      return {}
    }
  }

  const renderItems = () => {
    return (
      <div className={ `${can_access_approval_dots && "d-flex justify-content-between"}` }>
        <div className="align-self-center position-relative w-100">
          {can_access_edit_title ?
            <StageInlineEdit
              stage={stage}
              value={stage_name}
              edit_type="name"
              handleInlineEdit={(handleOnBlur, name) => handleInlineStageEdit(handleOnBlur, name, workspace_id, stage_id)}
              inputFocusWidth={180}
            />
          :
            <h2 className="h4 mb-0 w-100" style={{maxWidth: "fit-content"}}>
              { stage_name }
            </h2>
          }
        </div>
        <div className="align-self-center" style={stageSettingStyle()}>
          { can_access_approval_dots &&
            <div className="d-inline-block">
              { stage.require_approval_from_client &&
                <span className="stage__attribute-icon stage__attribute-icon--requires-approval"></span>
              }

              { stage.visible_to_client &&
                <span className="stage__attribute-icon stage__attribute-icon--visible-to-client"></span>
              }
            </div>
          }
          {
            (can_access_stage || can_access_content || can_access_sort_stage) &&
            <EditStageDropdown
              stage={stage}
              inactiveSearchText={inactiveSearchText}
              setShowAddContentGroupField={setShowAddContentGroupField}
            />
          }

        </div>
      </div>
    )
  }

  const inactiveSearchText = currentSearchText === '' || currentSearchText === null || currentSearchText === undefined;

  return (
    <div ref={stageRef}>
      {/*
        <Row className="d-none">
          <Col md={"3"} lg={"2"}>
            <div className="d-flex align-items-center justify-content-between button-padding-0" >
              <Button onClick={() => setFilter(filter_options[0])} className={filter == filter_options[0] && "active"} variant="link">All({content_groups ? content_groups.length : 0})</Button>
              <Button onClick={() => setFilter(filter_options[1])} className={filter == filter_options[1] && "active"} variant="link">Active({active_content_groups ? active_content_groups.length : 0})</Button>
              <Button onClick={() => setFilter(filter_options[2])} className={filter == filter_options[2] && "active"} variant="link">Archived({archived_content_groups ? archived_content_groups.length : 0})</Button>
            </div>
          </Col>
        </Row>
      */}

      <Draggable
        draggableId={rows.id.toString()}
        index={index}
        key={rows.id.toString()}
        isDragDisabled={true}
      >
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Droppable
                droppableId={rows.id.toString()}
                key={rows.id}
                type="CARD"
              >
                {(dropProvided, dropSnapshot) => {
                  return (
                    <div
                      className="stack-table mb-4 content-group-table_container"
                      id={isMobileScreen ? "responsive-mobile" : null}
                      ref={dropProvided.innerRef}
                      {...provided.droppableProps}
                    >
                      <table className="content-group-table table table-light table-bordered content-list-table responsiveTable">
                        <thead>
                          <tr>
                            <th style={{width: 400}} scope="col">
                              {renderItems()}
                            </th>
                            <th className="content-group-table__label content-group-table__title text-center" style={{width: !isMobileScreen ? '220px' : null}}>Labels</th>
                            <th className="content-group-table__due-date content-group-table__title text-center" style={{width: !isMobileScreen ? '190px' : null}}>
                              Due Date
                            </th>
                            <th className="content-group-table__stage content-group-table__title text-center" style={{width: !isMobileScreen ? '190px' : null}}>
                              Stage
                            </th>
                            {can_access_show_content_details &&
                              <th scope="col" className="content-group-table__participants content-group-table__title text-center" style={{width: !isMobileScreen ? '190px' : null}}>Participants</th>
                            }
                            <th className="content-group-table__last-updated content-group-table__title text-center" style={{width: !isMobileScreen ? '190px' : null}}>
                              Last Updated
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        { rows && rows.items.length > 0 ?
                          rows?.items.map((data, index) => {
                            return (
                              <Draggable
                                key={"column-"+data.id}
                                draggableId={"column-"+data.id}
                                index={index}
                                isDragDisabled={can_access_move_content_list ? false : true}>
                                {(dragProvided, dragSnapshot) => {
                                  return (
                                    <tr
                                      className="hovet-tr"
                                      {...dragProvided.draggableProps}
                                      ref={dragProvided.innerRef}
                                    >
                                      <td className="drag-icons-td pivoted">
                                        <div data-testid="td-before" className="tdBefore" style={{ width: '100%' }}>
                                          {renderItems()}
                                        </div>
                                         {can_access_move_content_list &&
                                            <div
                                              className="drag-icons"
                                              {...dragProvided.dragHandleProps}
                                            >
                                              <img height="25" src={DragIcon} title="Drag Content" />
                                            </div>
                                          }
                                        <Row
                                        >
                                          <Col style={{ flexGrow: 1 }}>
                                            <div
                                              className="d-flex align-items-center visible_div"
                                              data-id={data.id}
                                              data-image-pending={data.preview && data.preview.content_attachment && data.preview.content_attachment_type != 'application/pdf' ? 'true' : 'false'}
                                              id={`find_div_${data.id}`}
                                              >

                                              {
                                                data.preview && data.preview.content_attachment &&(
                                                  data.preview.content_attachment_type == "application/pdf" ?
                                                    <i className="content-group-table__icon fa fa-file-pdf-o" aria-hidden="true" style={{fontSize: 35}}></i>
                                                  :
                                                    <img
                                                      src={LoadiGif}
                                                      className="content-group-table__icon content_attachment_img"
                                                    />
                                                )
                                              }
                                              <Row>
                                                <Col sm={12}>
                                                  <div
                                                    onClick={() => handleOpenContentGroupPopUp(data.id)}
                                                    className="page-content-group-list__content-group-title cursor-pointer">{data.name}
                                                  </div>
                                                </Col>
                                                <Col sm={12}>
                                                  <span className="truncate">
                                                    { data.description }
                                                  </span>
                                                </Col>
                                              </Row>
                                              </div>
                                          </Col>
                                          {
                                            stage.require_approval_from_client && data?.stage?.submitted  &&
                                              <Col className="m-auto text-center" style={{ flexShrink: 1, flexGrow: 0, paddingLeft: 0 }}>
                                                { !data.stage.approved ?
                                                    data.stage.status == "pending" ?
                                                      <span className="content_group_icon_status-icon rejected"><i className="fa fa-thumbs-o-down text-white" aria-hidden="true"></i></span>
                                                    :
                                                      <span className="content_group_icon_status-icon submitted"><i className="fa fa-check text-white" aria-hidden="true"></i></span>
                                                  :
                                                    <span className="content_group_icon_status-icon approved"><i className="fa fa-thumbs-o-up text-white" aria-hidden="true"></i></span>
                                                }
                                              </Col>
                                          }
                                        </Row>
                                      </td>
                                      <td style={{width: !isMobileScreen ? '220px' : null}} className="pivoted">
                                        {isMobileScreen &&
                                          <div data-testid="td-before" className="tdBefore">
                                            Labels
                                          </div>
                                        }
                                        <LabelField
                                          can_access_edit_label={can_access_edit_label}
                                          workspace_id={workspace_id}
                                          content_group_id={data.id}
                                          labels={data.labels}
                                        />
                                      </td>
                                      <td className="pivoted text-center" style={{width: !isMobileScreen ? '190px' : null}}>
                                        {isMobileScreen &&
                                          <div data-testid="td-before" className="tdBefore">
                                            Due Date
                                          </div>
                                        }
                                        { can_access_add_change_date ?
                                            <DateTimeInlineEdit
                                              content_group_date={data.due_date}
                                              type="due_date"
                                              handleInlineEdit={(handleOnBlur, due_date) => handleInlineEdit(handleOnBlur, due_date, data.id,)}
                                              center={true}
                                            />
                                          :
                                            ( data.due_date ?
                                                dateTimeFormatter(data.due_date)
                                              :
                                                '-'
                                            )
                                        }
                                      </td>
                                      <td className="pivoted text-center" style={{width: !isMobileScreen ? '190px' : null}}>
                                        {isMobileScreen &&
                                          <div data-testid="td-before" className="tdBefore">
                                            Stage
                                          </div>
                                        }
                                        <StageLabel
                                          name={ stage_name }
                                          can_access_edit_title={ can_access_move_content_list }
                                          stages={ allowed_stages }
                                          scrollable={false}
                                          handleInlineEdit={(handleOnBlur, stage_id) => handleInlineEdit(handleOnBlur, stage_id, data.id)}
                                        />
                                      </td>


                                      {can_access_show_content_details &&
                                        <td data-th="Project: " style={{width: !isMobileScreen ? '190px' : null}} className="pivoted">
                                          {isMobileScreen &&
                                            <div data-testid="td-before" className="tdBefore">
                                              Participants
                                            </div>
                                          }
                                          <ParticipantField
                                            can_access_assigned_user={can_access_assigned_user}
                                            workspace_id={workspace_id}
                                            assigned_users={data.assigned_users}
                                            content_group_id={data.id}
                                          />
                                        </td>
                                      }
                                      <td className="pivoted text-center" style={{width: !isMobileScreen ? '190px' : null}}>
                                        {isMobileScreen &&
                                          <div data-testid="td-before" className="tdBefore">
                                            Last Updated
                                          </div>
                                        }
                                        { dateTimeFormatter(data.updated_at) }
                                      </td>
                                    </tr>
                                  );
                                }}
                              </Draggable>
                            );
                          })
                          :
                          !can_crud_content_group && !loading &&
                          <tr>
                            <td colSpan="7">
                              <div className="text-center py-3"> No Queue </div>
                            </td>
                          </tr>
                        }
                        {dropProvided.placeholder}
                        { loading &&
                          <tr>
                            <td colSpan="7">
                              <BasicSpinner />
                            </td>
                          </tr>
                        }
                        { rows?.page < rows?.total_pages || (can_access_content && inactiveSearchText) ?
                            <tr style={{borderBottomWidth: 0}} ref={addContentGroupRowRef}>
                              <td style={{borderBottomWidth: 1}} className="add-content-column">
                                { loading &&
                                  <Button className="disabled button-content-add d-block" disabled>Loading...</Button>
                                }

                                { rows?.page < rows?.total_pages && !loading &&
                                  <Button className="button-content-add d-block" onClick={() => loadNextPage(rows)}>Load More</Button>
                                }

                                {
                                  can_access_content && inactiveSearchText &&
                                  <>
                                    { showAddContentGroupField ?
                                       <NewContentGroupSingleFieldForm
                                         onSubmit={ handleNewContentGroupSubmit }
                                         handleClose={ handleCloseNewForm }
                                         addContentGroupRowRef={addContentGroupRowRef}
                                         handleOpenContentGroupPopUp={ handleOpenContentGroupPopUp }
                                         stageId={ stage_id } />
                                     :
                                       <Button className="button-content-add" onClick={() => setShowAddContentGroupField(true)}>
                                         <div className="button-icon__container d-inline-block">
                                          <CircleAddIcon color={"#8D8E9D"}/>
                                         </div>
                                         Add Card
                                       </Button>
                                   }
                                  </>
                                }

                              </td>
                            </tr>
                          :
                            can_access_content && inactiveSearchText &&
                              <tr ref ={addContentGroupRowRef} className="add-content-column">
                                <td className="add-content-column">
                                  { showAddContentGroupField ?
                                     <NewContentGroupSingleFieldForm
                                       onSubmit={ handleNewContentGroupSubmit }
                                       handleClose={ handleCloseNewForm }
                                       addContentGroupRowRef={addContentGroupRowRef}
                                       handleOpenContentGroupPopUp={ handleOpenContentGroupPopUp }
                                       stageId={ stage_id } />
                                   :
                                     <Button
                                      variant=""
                                      className="button-content-add"
                                      onClick={() => setShowAddContentGroupField(true)}
                                     >
                                       <div className="button-icon__container d-inline-block">
                                        <CircleAddIcon color={"#8D8E9D"}/>
                                       </div>
                                       Add Card
                                     </Button>
                                 }
                               </td>
                              </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  );
                }}
              </Droppable>
            </div>
          );
        }}
      </Draggable>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    created_content_group: state.content_group.created_content_group,
    destroyed_content_group: state.content_group.destroyed_content_group,
    updated_content_group: state.content_group.updated_content_group,
    stages_to_update: state.stage.stages_to_update,
    currentSearchText: state.search_history.current_search_text
  };
};

export default connect(mapStateToProps, { stageActions })(ContentGroupList);
