import { useState, useEffect, useRef, useContext  } from 'react'
import { useDispatch, connect } from "react-redux";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Card from "./card";
import { Row, Dropdown, Button, Spinner } from "react-bootstrap";
import { StageInlineEdit } from '../../../../components/StageInlineEdit.js';
import { EditStageDropdown } from '../../../../components';
import NewContentGroupSingleFieldForm from '../../ContentGroupPage/newSingleFieldForm'
import { stageActions, content_groupActions, alertActions } from "../../../../actions";
import InfiniteScroll from 'react-infinite-scroll-component';
import { WorkspaceShowContext } from "../../../../contexts/WorkspaceShowContext";
import { PerPageContentGroup, CircleAddIcon } from '../../../../components';
import { useIsVisible } from '../../../../utils/useIsVisible';

function CardGroup(props) {
  const {
    column,
    index,
    can_crud_content_group,
    workspace_id,
    organization_id,
    can_access_move_content_board,
    can_access_edit_title,
    can_access_move_stage_board,
    can_access_show_content_details,
    can_access_approval_dots,
    stages_to_update,
    updated_content_group,
    currentSearchText,
    can_access_edit_label
  } = props;
  const [showAddContentGroupField, setShowAddContentGroupField] = useState(false);
  const dispatch = useDispatch();
  const addContentGroupRowRef = useRef();
  const [loading, setLoading] = useState(false);
  const perPage = PerPageContentGroup;
  const stageRef = useRef();
  const isVisible = useIsVisible(stageRef);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [editModeCatcher, setEditModeCatcher] = useState(false);
  const loading_ref = useRef(loading)

  const addContentGroups = (result) => {
    const reset_content_group = false;
    addContentGroupToStage(column, result.content_groups, result.total_pages, result.has_next_page, reset_content_group)
  }

  const resetContentGroups = (result) => {
    const reset_content_group = true;
    addContentGroupToStage(column, result.content_groups, result.total_pages, result.has_next_page, reset_content_group)
  }

  const loadNextPageContentGroups = (result) => {
    const reset_content_group = true;
    addContentGroupToStage(column, result.content_groups, result.total_pages, result.has_next_page, reset_content_group)
  }

  const {
    can_access_stage,
    can_access_content,
    can_access_sort_stage,
    nextPage,
    addContentGroupToStage,
    resetStage,
    removeContentGroupToStage,
    replaceContentGroupToStage,
    handleSortByDueDateStage,
    handleOpenContentGroupPopUp,
    handleShowDeleteStagePopup,
    refreshContentGroupToStage,
    loadTracker,
    query
  } = useContext(WorkspaceShowContext);

  const refreshListPaginate = (result) => {
    refreshContentGroupToStage(column, result.content_groups)
  }

  useEffect(() => {
    loading_ref.current = loading
  }, [loading]);

  useEffect(() => {
    if(props?.created_content_group && column?.id === props?.created_content_group.stage_id){
      addContentGroupToStage(column, [props.created_content_group], column.total_pages)
    }
  }, [props?.created_content_group]);

  useEffect(() => {
    if(props?.destroyed_content_group && column?.id == props?.destroyed_content_group.stage_id){
      removeContentGroupToStage(column, props.destroyed_content_group)
    }
  }, [props?.destroyed_content_group]);

  useEffect(() => {
    if(column && isVisible && column?.page > 1){
      dispatch(stageActions.listPaginate(workspace_id, column?.id, addContentGroups, setLoading, column?.page, perPage, currentSearchText));
    }
  }, [column?.page]);

  useEffect(() => {
    if(loading_ref.current) return;

    if (currentSearchText !== '' || !isVisible) {
      setInitialLoaded(false)
    } else {
      setInitialLoaded(true)
    }
    if(column && isVisible){
      resetStage(column);
      dispatch(stageActions.listPaginate(workspace_id, column?.id, resetContentGroups, setLoading, column?.page, perPage, currentSearchText));
    }
  }, [currentSearchText]);

  useEffect(() => {
    if(isVisible && !initialLoaded && column?.page === 1){
      const search = query.get('search');
      dispatch(stageActions.listPaginate(workspace_id, column?.id, resetContentGroups, setLoading, 1, perPage, search));
      setInitialLoaded(true);
    } else if(isVisible && initialLoaded){
      console.log("Card Group isVisible refreshListPaginate")
      dispatch(stageActions.refreshListPaginate(workspace_id, column?.id, refreshListPaginate, 1, column?.page * perPage, currentSearchText));
    }
  }, [isVisible]);


  useEffect(() => {
    if (column && stages_to_update){
      let reset_this_stage = stages_to_update.map((e) => e.id).includes(column.id)

      if (reset_this_stage) {
        resetStage(column);

        if(isVisible){
          dispatch(stageActions.listPaginate(workspace_id, column?.id, resetContentGroups, setLoading, 1, perPage, currentSearchText));
        }

        setInitialLoaded(false);
      }
    }
  }, [stages_to_update]);

  useEffect(() => {
    if (updated_content_group) {
      let current_content_group_index = column?.items.findIndex(r => r.id === updated_content_group.id)

      if (current_content_group_index !== -1) {
        if (updated_content_group) {
          if (column?.id != updated_content_group.stage_id) {
            removeContentGroupToStage(column, updated_content_group)
          } else {
            replaceContentGroupToStage(column, updated_content_group)
          }
        }
      } else {
        if (column?.id == updated_content_group.stage_id) {
          addContentGroupToStage(column, updated_content_group)
        }
      }
    }
  }, [updated_content_group]);

  useEffect(() => {
    if(isVisible && initialLoaded && loadTracker && !loading_ref.current){
      console.log(column)
      console.log('Card Group Load Tracker')
      dispatch(stageActions.refreshListPaginate(workspace_id, column?.id, refreshListPaginate, 1, column?.page * perPage, currentSearchText));
    }
 }, [loadTracker]);

  const handleInlineEdit = (handleOnBlur, values, workspace_id, stage_id) => {
     if(can_access_edit_title) {
      dispatch(stageActions.inlineEditStage(handleOnBlur, values, workspace_id, stage_id));
    }else {
      dispatch(alertActions.error("You are not authorized to access this page"));
    }
  }

  const handleShowNewForm = () => {
    setShowAddContentGroupField(true);
  };

  const handleCloseNewForm = () => {
    setShowAddContentGroupField(false);
  }

  const handleCreateContentGroupSuccess = (id) => {
    setShowAddContentGroupField(false)
  };

  const handleNewContentGroupSubmit = (values) => {
    dispatch(content_groupActions.createContentGroup(values, workspace_id, handleCreateContentGroupSuccess));
  };

  const stageHeaderRef = useRef(null);

  const dropStyle = (snapshot) => {
    return {
      background: snapshot.isDraggingOver
        ? "lightgrey"
        : "none",
      minWidth: 250,
      overflowY: "auto",
      maxHeight: "100%"
    }
  };

  const inactiveSearchText = currentSearchText === '' || currentSearchText === null || currentSearchText === undefined;

  return (
    <div
      className="kanban-board"
      ref={stageRef}
    >
      <Draggable
        draggableId={"column-"+column.id.toString()}
        index={index}
        key={"board-"+column.id.toString()}
        isDragDisabled={can_access_move_stage_board ? false : true}
      >
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              className={ "kanban-card-group" + (column.items && column.items.length == 0 ? " kanban-card-group--empty" : "") }
              style={{
                userSelect: "none",
                ...provided.draggableProps.style,
              }}
            >

            <div
              className={`kanban-card-group_header d-flex justify-content-between`}
              {...provided.dragHandleProps}
            >
              <div ref={stageHeaderRef} className="align-self-center position-relative">
                {
                  //can_crud_content_group ?
                  can_access_edit_title ?
                    <StageInlineEdit
                      stage={column}
                      value={column.name}
                      edit_type="name"
                      can_access_edit_title={can_access_edit_title}
                      handleInlineEdit={(handleOnBlur, name) => handleInlineEdit(handleOnBlur, name, workspace_id, column.id)}
                      inputFocusWidth={180}
                    />
                  :
                    <h2 className="h4 mb-0 w-100" style={{maxWidth: "fit-content"}}>
                      { column.name }
                    </h2>
                }
              </div>
              <div className="align-self-center">
                {can_access_approval_dots && !editModeCatcher &&
                  <>
                    {column.visible_to_client &&
                      <span className="stage__attribute-icon stage__attribute-icon--visible-to-client"></span>
                    }

                    { column.require_approval_from_client &&
                      <span className="stage__attribute-icon stage__attribute-icon--requires-approval"></span>
                    }
                  </>
                }

                {
                  (can_access_stage || can_access_content || can_access_sort_stage) &&
                  <EditStageDropdown
                    stage={column}
                    inactiveSearchText={inactiveSearchText}
                    setShowAddContentGroupField={setShowAddContentGroupField}
                  />
                }
              </div>
            </div>

              <Droppable
                droppableId={column.id.toString()}
                key={column.id}
                type="CARD"
              >
                {(dropProvided, dropSnapshot) => {
                  return (
                    <div
                      ref={dropProvided.innerRef}
                      style={{
                        background: dropSnapshot.isDraggingOver
                          ? "lightgrey"
                          : "none",
                        minWidth: 250,
                        marginRight: '-6px',
                        paddingRight: '6px',
                        maxHeight: `calc(100% - ${can_access_content ? (showAddContentGroupField ? '125px' : '120px') :  '50px'})`
                      }}
                    >
                      <InfiniteScroll
                        dataLength={column?.items?.length}
                        hasMore={loading ? false : column?.has_next_page}
                        loader={
                          <div className="clearfix">
                            <div className="spinner-container">
                              <Spinner animation="border" />
                            </div>
                          </div>
                        }
                        height={ '100%' }
                        className="vertical-scroll_thin no-bg"
                        next={() => nextPage(column)}
                      >
                        {column?.items.map((item, index) => {
                          return (
                            <Card
                              item={item}
                              index={index}
                              key={item.id}
                              can_crud_content_group={can_crud_content_group}
                              workspace_id={workspace_id}
                              organization_id={organization_id}
                              can_access_content={can_access_content}
                              can_access_move_content_board={can_access_move_content_board}
                              can_access_show_content_details={can_access_show_content_details}
                              can_access_edit_label={can_access_edit_label}
                            />
                          );
                        })}
                        {dropProvided.placeholder}
                      </InfiniteScroll>
                      {dropProvided.placeholder}
                    </div>
                  );
                }}
              </Droppable>

              { !can_crud_content_group && column.items && column.items.length == 0 &&
                <div className="kanban-column__no-cards">
                  No Queue
                </div>
              }


              {can_access_content && inactiveSearchText &&(
                <div
                  className="kanban-card add-content_container"
                  style={{userSelect: "none"}}
                  ref={addContentGroupRowRef}
                >
                  { showAddContentGroupField ?
                      <NewContentGroupSingleFieldForm
                        handleClose={ handleCloseNewForm }
                        onSubmit={ handleNewContentGroupSubmit }
                        handleOpenContentGroupPopUp={ handleOpenContentGroupPopUp }
                        addContentGroupRowRef={ addContentGroupRowRef }
                        stageId={ column.id } />
                    :
                      <Button className="button-content-add" onClick={() => setShowAddContentGroupField(true)}>
                        <div
                          className="mr-2 d-inline-block"
                        >
                          <CircleAddIcon color="#8D8E9D" />
                        </div>
                        Add Card
                      </Button>
                  }
                </div>
              )}
            </div>
          );
        }}
      </Draggable>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    created_content_group: state.content_group.created_content_group,
    destroyed_content_group: state.content_group.destroyed_content_group,
    updated_content_group: state.content_group.updated_content_group,
    stages_to_update: state.stage.stages_to_update,
    currentSearchText: state.search_history.current_search_text
  };
};

export default connect(mapStateToProps, {})(CardGroup);
