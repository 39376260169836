import { useState, useEffect, useRef, useCallback} from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import autosize from "autosize";
import remarkGfm from 'remark-gfm'
import { useMediaQuery } from 'react-responsive'
import CheckIcon from "../images/icons/white/check.svg";
import { useDispatch } from 'react-redux';
import { buildFormDataFromObject } from '../utils/commonUtils';
import { content_groupActions, contentActions } from "../actions";
import { Image } from 'react-bootstrap';
import { LoadSpinner } from '../components';
import { useSelector } from "react-redux";

function ContentInlineEdit(props) {
  const {
    content_data,
     content_attachment_type,
     inline_type,
     content_height,
     hasContents,
     workspace_id,
     content_group_id,
     content_popup_notification
  } = props
  const dispatch = useDispatch();
  const [ contentValue, setContentValue] = useState(content_data && content_data);
  const [ editMode, setEditMod] = useState(!content_data);
  const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const [file, setFile] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const input = useRef(null);
  const editorEnd = useRef(null);

  let ignoreEdit = false;

  useEffect(() => {
    setContentValue(content_data)
    setEditMod(!content_data)
  }, [content_data]);

  const scrollToBottom = () => {
    if (editorEnd.current) {
      editorEnd.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }

  const onKeyDown = (event) => {
    if (inline_type === 'content_description') {
      return;
    }

    if (event.key === "Escape") {
      setEditMod(false);
      setContentValue(content_data);

      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    if (event.key === "Enter") {
      if (!editMode) {
        return;
      }

      event.target.blur();
    }
  }

  const handleEnableEditMode = useCallback(() => {
    setEditMod(true)
    setTimeout(() => {
      input.current.focus();
      scrollToBottom();
    }, 50);
  }, [input]);


  const handleOnBlur = (e) => {
    if (!editMode) {
      return;
    }
    props.handleContentInlineEdit({title: e.target.value})
  }

  const handleInputOnChange = (event) => {
    setContentValue(event.target.value)
  }

  const setFocused = (e) => {
    e.target.select();
    e.target.classList.add("focus_edit_input");
  }

  const handleFileChange = e => {
    setContentValue(URL.createObjectURL(e.target.files[0]));
    props.handleContentInlineEdit({attachment: e.target.files[0]})
  }

  const handleSubmit = (e) => {
    let from_content_popup_notif = false;
    let handleOnBlur = null;
    let formData = new FormData();

    let values = { description: contentValue };

    buildFormDataFromObject(formData, values, "content_group");

    dispatch(content_groupActions.inlineEditContentGroup(handleOnBlur, formData, content_group_id, workspace_id, from_content_popup_notif, setSubmitDisabled));
  }

  const handleCancel = () => {
    if(content_data === contentValue) {
      setEditMod(false)
    } else {
      setContentValue(content_data || '');
    }
  }

  const hiddenFileInput = useRef(null);

  const handleOnBlurOnDiv = (e) => {
    if(content_data !== input.current.value) {
      props.handleContentInlineEdit({description: input.current.value})
    }
  }

  const submitButtonStatus = (input.current && ((input.current.value === '') || content_data === input.current.value));

  const render_component = () => {
    if(inline_type === "content_title") {
      return(
        editMode === false ?
          <h5 onClick={handleEnableEditMode} className="mb-2 title-top">
            {contentValue}
          </h5>
        :
          <div style={{position:'relative'}} onBlur={(e) => handleOnBlur(e)}>
            <input
              type="text"
              ref={input}
              value={contentValue}
              className="form-control inline-edit-border"
              onKeyDown={onKeyDown}
              placeholder="Add Title"
              onChange={handleInputOnChange}
              onFocus={ setFocused }
            />
          </div>
      )
    }else if(inline_type === "file_upload") {
      return (
        content_data &&
          <div className="file_upload_content">
            <input type="file" onChange={handleFileChange} ref={hiddenFileInput} hidden/>
            {content_data &&
              <a href={ content_data } target="_blank">
                { content_attachment_type == "application/pdf" ?
                  <i className="fa fa-file-pdf-o mr-2 big-icon" aria-hidden="true"></i>
                :
                  <img src={contentValue} alt="content" style={{ minHeight: "" + content_height + "px", maxHeight: "" + content_height + "px" }} />
                }
              </a>
            }
          </div>
      )
    }else {
      return(
        <>
          {
            editMode === false ?
              <div
                {...(isMobileScreen ? { onClick: handleEnableEditMode} : {onDoubleClick: handleEnableEditMode})}
                className="content-description content-description--editable"
              >
                <ReactMarkdown
                  remarkPlugins={[remarkGfm, remarkBreaks]}
                  components={{
                    a: props => {
                      return (
                        <a href={props.href} onClick={(e) => { e.stopPropagation(); return true; }}className="link_color" target="_blank">{props.children}</a>
                      )
                    }
                  }}
              >
                {content_data}
              </ReactMarkdown>
              </div>
            :
              <div style={{ position: "relative" }}>
                <div>
                  <textarea
                    rows={8}
                    ref={input}
                    className="form-control-custom"
                    value={contentValue || ''}
                    placeholder="Add text"
                    onChange={handleInputOnChange}
                    onKeyDown={onKeyDown}
                    onKeyUp={(event) => {
                      event.target.focus();
                      autosize(event.target);
                    }}
                    onFocus={(event) => {
                      setFocused(event);
                      autosize(event.target);
                    }}
                    style={{ maxHeight: 'none' }}
                  />
                </div>
                <div className="button-group description_button-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={submitButtonStatus}
                  >
                    <img src={CheckIcon} className="button-icon" />
                    Save
                  </button>
                  <input
                    type="submit"
                    className="btn btn-default"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={handleCancel}
                    value="Cancel"
                    disabled={submitButtonStatus}
                  />
                </div>

                <div ref={ editorEnd } className="description-section__bottom">
                </div>
              </div>
          }
        </>
      )
    }
  }

  return (
    <div className="content_inline_edit">
      {render_component()}
    </div>
  );
}

export { ContentInlineEdit };
