import React, { useEffect, useState } from 'react';
import { validation } from '../../../shared/form-validations';
import { textInput } from '../../../shared/form-elements'
import { connect } from 'react-redux';
import { reduxForm, Field, change, formValueSelector } from "redux-form";
import { Button } from 'react-bootstrap';
import { ContentLabel } from '../../../components';

function LabelForm(props) {
  const { handleSubmit, changeMode, name, colorHex, labelId, formMode, onDelete  } = props
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className="text-center">
          <ContentLabel name={!!name ? name : "Enter label name"} color_hex={!!colorHex ? colorHex : "dddddd"} />
        </div>

        <Field 
          name="name" 
          type="text" 
          autoFocus
          label="Label Name" 
          component={textInput} 
        />
      </div>
      <div>
        <Field 
          name="color_hex" 
          type="text" 
          label="Color HEX" 
          component={textInput} 
        />
      </div>
      <div className="form-buttons">
        <Button 
          onClick={formMode === "edit_mode" ? (e) => onDelete(labelId, e) : changeMode }
          variant="default" 
          style={{marginRight: 20, fontSize: 14}}
        >
          {formMode === "edit_mode" ? "Delete" : "Cancel"}
        </Button>
        <Button 
          variant="primary" 
          type="submit"
        >
          Submit
        </Button>
      </div>
    </form>
  );
}

LabelForm =  reduxForm({
  form: 'content_label_form',
  validate: validation,
  enableReinitialize : true,
})(LabelForm);

const contentLabelFormSelector = formValueSelector('content_label_form')
LabelForm = connect(state => {
  const name = contentLabelFormSelector(state, 'name')
  const colorHex = contentLabelFormSelector(state, 'color_hex')

  return {
    name,
    colorHex
  }
})(LabelForm);

export default LabelForm;
