import { alertActions } from './';
import { userConstants, searchHistoryConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makePOSTRequest, makeDELETERequest, makePUTRequest } from '../utils/Axios';
import { googleLogout } from '@react-oauth/google';
import { isLoggedIn } from '../utils';

export const userActions = {
  responseGoogle,
  login,
  logout,
  register,
  newPassword,
  resetPassword,
  resendConfirmation,
  requestUnlock,
  unlockAccount,
  confirm,
  showInvitation
};

export function login(reqparams, errorCallback) {
  return dispatch => {
    try{
      makePOSTRequest('/api/v1/auth', reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({type: userConstants.LOGIN_REQUEST, payload: response});
          dispatch({type: userConstants.LOGIN_SUCCESS, payload: response.data});
          localStorage.setItem('user', JSON.stringify(response.data.token));
          localStorage.setItem('user_role', response.data.can_acces_admin_login);
          localStorage.setItem('org_id', response.data.organization_id);
          // localStorage.setItem('user_role', response.data.user_role);
          dispatch({type: userConstants.CURRENT_USER_DATA, payload: response.data.current_user})
          const userRole = response.data.can_acces_admin_login
          // const userRole = response.data.user_role
          const redirect_url = (URL) =>{
            window.location.href = URL;
          }

          if(userRole === "admin") {
            history.push("/");
          }else {
            if (response.data.workspace_count == 1 && response.data.workspace){
              history.push(`/organizations/${response.data.workspace.organization_id}/workspaces/${response.data.workspace.id}/list`);
            } else {
              history.push("/");
            }
          }

          dispatch(alertActions.success(response.data.message));
        }else {
          dispatch({type: userConstants.LOGIN_FAILURE, payload: response});
          errorCallback(response.data.message);
          dispatch(alertActions.error(response.data.message));
        }
      })
    }catch{

    }
  }
}

export function responseGoogle(user) {
  return dispatch => {
    try{
      makePOSTRequest('/api/v1/auth/request', {user})
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({type: userConstants.RESPONSE_GOOGLE_SUCCESS, payload: response})
          localStorage.setItem('user', JSON.stringify(response.data.token));
          localStorage.setItem('googleLogin', true);
          // localStorage.setItem('fname', response.data.fname);
          // localStorage.setItem('profile_img', response.data.profile_img);
          // localStorage.setItem('email', response.data.email);
          localStorage.setItem('user_role', response.data.can_acces_admin_login);
          localStorage.setItem('org_id', response.data.organization_id);
          dispatch({type: userConstants.CURRENT_USER_DATA, payload: response.data.current_user})
          const userRole = response.data.can_acces_admin_login
          if (response.data.workspace_count == 1 && response.data.workspace){
            history.push(`/organizations/${response.data.workspace.organization_id}/workspaces/${response.data.workspace.id}/list`);
          } else {
            history.push("/");
          }
          dispatch(alertActions.success(userConstants.RESPONSE_GOOGLE_SUCCESS));
        }else {
          dispatch({type: userConstants.RESPONSE_GOOGLE_FAILURE, payload: response.error})
          dispatch(alertActions.error(userConstants.RESPONSE_GOOGLE_FAILURE));
        }
      })
    }catch{

    }
  }
}

export function register(reqparams, successCallback) {
  return dispatch => {
    try{
      makePOSTRequest('/users', reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({type: userConstants.REGISTER_REQUEST, payload: response})
          dispatch({type: userConstants.REGISTER_SUCCESS, payload: response})

          if(response.data.confirmed){
            dispatch(alertActions.success(userConstants.REGISTER_SUCCESS_VERIFIED));
          }
          else{
            dispatch(alertActions.success(userConstants.REGISTER_SUCCESS));
          }
          successCallback();
        }else {
          dispatch(alertActions.error(response.data.error));
          dispatch({type: userConstants.REGISTER_FAILURE, payload: response.error})
          dispatch(alertActions.error(response.data.error));
        }
      })
    }catch{

    }
  }
}

export function showInvitation(invite_id) {
  return dispatch => {

    if (invite_id === null || invite_id === undefined){
      return dispatch({type: userConstants.REMOVE_INVITATION, payload: null});
    }

    try{
      makeGETRequest(`/api/v1/users/new/${invite_id}`)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({type: userConstants.SET_INVITATION, payload: response})
        }else {
          dispatch({type: userConstants.REMOVE_INVITATION, payload: response})
        }
      })
    }catch{
      dispatch({type: userConstants.REMOVE_INVITATION, payload: null})
    }
  }
}

export function confirm(reqparams) {
  return dispatch => {
    try{
      makeGETRequest('/users/confirmation?confirmation_token='+ reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({type: userConstants.CONFIRM_SUCCESS, payload: response})
          dispatch(alertActions.success(userConstants.CONFIRM_SUCCESS));
        }else {
          history.push('/');
          dispatch(alertActions.error(response.data.error));
          dispatch({type: userConstants.CONFIRM_FAILURE, payload: response.error})
        }
      })
    }catch{

    }
  }
}

export function logout(custom_flash) {
  return function (dispatch) {
    if(isLoggedIn()){
      try{
        var histories = localStorage.getItem(searchHistoryConstants.KEY);
        localStorage.clear();
        if(histories !== undefined && histories !== null){
           localStorage.setItem(searchHistoryConstants.KEY, histories);
        }
        makeDELETERequest(`/api/v1/logout`)
        .then(response => {
          dispatch({
            type: userConstants.LOGOUT,
            payload: response
          });
          history.push("/login");
          googleLogout();
          if(custom_flash){
            custom_flash();
          } else{
            dispatch(alertActions.success(userConstants.LOGOUT));
          }
        })
      }catch{

      }
    }
  }
}


export function newPassword(reqparams, successCallback) {
  return dispatch => {
    try{
      makePOSTRequest('/api/v1/user/password/new', reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({type: userConstants.NEWPASSWORD_REQUEST, payload: response})
          dispatch({type: userConstants.NEWPASSWORD_SUCCESS, payload: response})
          successCallback();
          //dispatch(alertActions.success(userConstants.NEWPASSWORD_SUCCESS));
        }else {
          dispatch(alertActions.error(response.data.error));
          dispatch({type: userConstants.NEWPASSWORD_FAILURE, payload: response.error})
        }
      })
    }catch{

    }
  }
}

export function resetPassword(reqparams) {
  return dispatch => {
    try{
      makePUTRequest('/api/v1/user/password/update', reqparams)
      .then(response => {
        if(response.data.status === "ok"){
          dispatch({type: userConstants.CHANGEPASSWORD_REQUEST, payload: response})
          dispatch({type: userConstants.CHANGEPASSWORD_SUCCESS, payload: response})
          history.push('/');
          dispatch(alertActions.success(userConstants.CHANGEPASSWORD_SUCCESS));
        }else {
          dispatch(alertActions.error(response.data.error));
          dispatch({type: userConstants.CHANGEPASSWORD_FAILURE, payload: response.error})
        }
      })
    }catch{

    }
  }
}

export function resendConfirmation(reqparams, successCallback) {
  return dispatch => {
    try{
      makePOSTRequest('/api/v1/users/resend_confirmation', reqparams)
      .then(response => {
        if(response.data.error){
          dispatch(alertActions.error(response.data.message));
        }else {
          dispatch(alertActions.success(response.data.message));
          successCallback();
        }
      })
    }catch{

    }
  }
}

export function requestUnlock(reqparams, successCallback) {
  return dispatch => {
    try{
      makePOSTRequest('/api/v1/users/request_unlock', reqparams)
      .then(response => {
        if(response.data.error){
          dispatch(alertActions.error(response.data.message));
        }else {
          dispatch(alertActions.success(response.data.message));
          successCallback();
        }
      })
    }catch{

    }
  }
}

export function unlockAccount(reqparams) {
  return dispatch => {
    try{
      makePOSTRequest('/api/v1/users/unlock_account', reqparams)
      .then(response => {
        history.push('/login');
        if(response.data.error){
          dispatch(alertActions.error(response.data.message));
        }else {
          dispatch(alertActions.success(response.data.message));
        }
      })
    }catch{

    }
  }
}
