import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../../../actions/notification.actions.js';
import { history } from '../../../helpers';
import { Button, Table, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { work_spaceActions } from '../../../actions';
import { Link } from 'react-router-dom';
import ShowIcon from '../../../images/icons/show.svg';
import HideIcon from '../../../images/icons/hide.svg';
import DotsIcon from '../../../images/dots.png';
import { UserAvatar } from '../../../components';
import { adminBaseURL } from '../../../utils/commonUtils';
import { renderNameAndSummary } from '../../../utils/';

function NotificationsDropdown(props) {
  const dispatch = useDispatch();
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const {
    current_organization,
    notifications,
    handleHideNotificationsDropdown,
    can_access_content,
    can_access_edit_title,
    handleModalCloseNotification,
    notification_load,
    showNotificationsDropdown
  } = props
  const [ filter_notifications, set_filter_notifications] = useState(notifications ? notifications : []);
  const [ filter_activites, set_filter_activites] = useState(false);
  const [ hasNextPage, setHasNextPage ] = useState(false);
  const work_space = useSelector(state => state.work_space.currentWorkSpace);
  const [show_option_dropdown, set_show_option_dropdown] = useState(false)

  useEffect(() => {
    if(showNotificationsDropdown){
      loadNotifications(null)
    }
  }, [showNotificationsDropdown, filter_activites]);

  useEffect(() => {
    if (notification_load) {
      let current_notifications = filter_notifications

      let notification = current_notifications.filter(item => item.id == notification_load.id)[0]

      if (!!notification){
        notification.is_read = notification_load.is_read

        set_filter_notifications(current_notifications.map((item) => item))
      }
    }

  }, [notification_load]);

  const loadNotifications = (lastNotificationId) => {
    let success_callback = (data) => {
      set_filter_notifications(data.data)
    }
    const isUserRole = localStorage.getItem('user_role')

    const filter_type = isUserRole === "admin" && current_organization ? 'an_hour' : 'a_week';

    dispatch(notificationActions.getPage(filter_activites, lastNotificationId, success_callback, filter_type))
  }

  const handleRead = (notificationId) => {
    dispatch(notificationActions.read(notificationId));
  }

  const handleUnRead = (notificationId) => {
    dispatch(notificationActions.unRead(notificationId));
  }

  const hideNotificationPopup = () => {
    props.handleHideNotificationsDropdown()
  }

  const handleReadAll = () => {
    dispatch(notificationActions.readAll(() => loadNotifications(null)));
    set_show_option_dropdown(false);
  }

  const onSwitchNotificaton = () => {
    set_filter_activites(!filter_activites)
  }

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest('a');
    if(!targetLink) return;
    e.preventDefault();
    var content_id = targetLink.getAttribute("data-contentId")
    var workspace_id = targetLink.getAttribute("data-workspaceId")
    var organization_id = targetLink.getAttribute("data-organizationId")
    var content_name = targetLink.getAttribute("data-contentName")

    handleModalCloseNotification(content_id, workspace_id, work_space?.updated_at, can_access_content, can_access_edit_title)
  };

  const optionsClickHandler = (e) => {
    if (show_option_dropdown){
      set_show_option_dropdown(false)
    } else {
      set_show_option_dropdown(true)
    }
  }

  const workspaceClickHandler = (e, organization_id, workspace_id) =>{
    e.stopPropagation();

    window.location = `/organizations/${organization_id}${workspaceBaseUrl}/${workspace_id}/list`

    return true;
  }

  const viewAllNotificationHandler = () => {
    handleHideNotificationsDropdown()

    window.location = `/notifications`
  }

  const renderSummaryHtml = (data) => {
    const bold_name = true;
    const one_line = true;

    return renderNameAndSummary(data?.actor?.name, data?.summary, bold_name, one_line);
  }

  return (
    <div>
      <div className="notifications-dropdown">
        <div className="notifications-dropdown__header d-flex align-items-center justify-content-between">
          <h5 className="mb-0 font-weight-bold">Notifications</h5>
          <div className="d-flex align-items-center notification_options">
            <span onClick={onSwitchNotificaton} className="cursor-pointer btn-sm btn-read_switch mx-1">
              {filter_activites ?
                  <img src={HideIcon} height="12" className="button-icon" />
                :
                  <img src={ShowIcon} height="12" className="button-icon" />
              }
              Read
            </span>
            <Dropdown className="d-inline-block notification_dropdwon" show={show_option_dropdown} onToggle={() => optionsClickHandler()}>
              <Dropdown.Toggle id="dropdown-basic" className="btn-transparent" >
                <img src={DotsIcon} height="12" className="button-icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{right: "auto"}}>
                <ul className="notification_ul">
                  <li><a onClick={handleReadAll}> Mark all as read </a></li>
                  <li><Link to="#" onClick={viewAllNotificationHandler}>View All</Link></li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="notifications-dropdown__content">
          <Table responsive borderless>
            <tbody>
              { filter_notifications && filter_notifications.length > 0 ?
                  filter_notifications.map((data, index) => (
                    <tr
                      key={'notification_dropdown_'+data.id}
                      onClick={() => data.is_read ? handleUnRead(data.id) : handleRead(data.id)}
                    >
                      <td
                        className={data.is_read ? 'notifications read_message' : 'notifications unread_message'}
                        onClick={contentClickHandler}
                      >
                        <div className="d-flex w-90">
                          <div className="notification__image-wrapper">
                            { data.actor &&
                              <UserAvatar
                                icon={ data.actor.icon }
                                name={ data.actor.name }
                                name_initials={ data.actor.name_initials }
                                size="32"
                                user_id={data.actor.user_id}
                                workspace_id={ data.workspace_id }
                                organization_id={ data.organization_id }
                                openUserSummary={true}
                              />
                            }
                          </div>

                          <div className="w-100">
                            <div className="notification__description" dangerouslySetInnerHTML={{__html: renderSummaryHtml(data)}} />
                            <div className="notifications-dropdown__notification-timestamp" title={ data.created_at }>
                              <small >
                                <span className="mr-3">
                                { data.created_at_time_from_now }
                                </span>
                                <span className="workspace_link" onClick={(e) => workspaceClickHandler(e, data.organization_id, data.workspace_id)}>
                                  { data.workspace_name }
                                </span>
                              </small>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                :
                <tr>
                  <td colSpan="2">
                    <div className="d-flex justify-content-center py-4">
                      There are no notifications.
                    </div>
                  </td>
               </tr>
              }
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    current_organization: state.organization.current_organization,
    loading: state.workspaceUser.loading,
    notifications: state.notification.notifications,
    notification_load: state.notification.notification_load,
    can_access_content: state.notification.can_access_content,
    can_access_edit_title: state.notification.can_access_edit_title
  };
};

export default connect(mapStateToProps, { notificationActions })(NotificationsDropdown);
