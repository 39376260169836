export default {
  Upgrade:{
    Details: require('../images/popup/upgrade_panel.webp')
  },
  Hide: require('../images/hide.png'),
  Replace: require('../images/replace.png'),
  History: require('../images/history.png'),
  Trash: require('../images/trash.png'),
  Unhide: require('../images/un_hide.png'),
  Layout: {
    LoginBackground: require('../images/login-background.jpg'),
  }
};
