import React, { useContext, useState, useEffect, useRef } from "react";
import { Dropdown, Image, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { stageActions } from '../../actions/';
import MoreVertival from '../../images/more-vertical.svg';
import {
  CircleAddIcon,
  UpArrowIcon,
  DownArrowIcon,
  EditIcon,
  TimeIcon,
  EyeIcon,
  CheckIcon
} from '../../components'
import { WorkspaceShowContext } from "../../contexts/WorkspaceShowContext";

function EditStageDropdown(props) {
  const {
    stage,
    setShowAddContentGroupField,
    inactiveSearchText
  } = props;
  const dispatch = useDispatch();
  const {
    workspace_id,
    can_access_stage,
    can_access_content,
    can_access_sort_stage,
    handleSortByDueDateStage,
    handleShowDeleteStagePopup
  } = useContext(WorkspaceShowContext);
  const [enable_revision, setEnableRevision] = useState(false)
  const [enable_timestamp, setEnableTimeStamp] = useState(false)
  const [visible_to_client, setVisibleToClient] = useState(false)
  const [require_approval_from_client, setRequireApprovalFromClient] = useState(false)

  const handleOnBlur = () => {};
  const handleInlineStageEdit = (event) => {

    let values = {}
    values[event.target.name] = event.target.checked

    switch(event.target.name){
      case 'enable_revision':
        setEnableRevision((prevState) => stage[event.target.name])
        break;
      case 'enable_timestamp':
        setEnableTimeStamp((prevState) => stage[event.target.name])
        break;
      case 'visible_to_client':
        setVisibleToClient((prevState) => stage[event.target.name])
        break;
      case 'require_approval_from_client':
        setRequireApprovalFromClient((prevState) => stage[event.target.name])
        break;
    }

    dispatch(stageActions.inlineEditStage(handleOnBlur, values, workspace_id, stage?.id));
  }

  const handleClickStageSetting = (setting_name) =>{
    let values = {}

    switch(setting_name){
      case 'enable_revision':
        values[setting_name] = !enable_revision
        setEnableRevision((prevState) => !enable_revision)
        break;
      case 'enable_timestamp':
        values[setting_name] = !enable_timestamp
        setEnableTimeStamp((prevState) => !enable_timestamp)
        break;
      case 'visible_to_client':
        values[setting_name] = !visible_to_client
        setVisibleToClient((prevState) => !visible_to_client)
        break;
      case 'require_approval_from_client':
        values[setting_name] = !require_approval_from_client
        setRequireApprovalFromClient((prevState) => !require_approval_from_client)
        break;
    }

    dispatch(stageActions.inlineEditStage(handleOnBlur, values, workspace_id, stage?.id));
  }

  useEffect(() => {
    if(stage){
      setEnableRevision((prevState) => stage.enable_revision)
      setEnableTimeStamp((prevState) => stage.enable_timestamp)
      setVisibleToClient((prevState) => stage.visible_to_client)
      setRequireApprovalFromClient((prevState) => stage.require_approval_from_client)
    }
  }, [stage])

  return (
    <>
      {
        stage &&
        <Dropdown className="d-inline-block stage-dropdown">
          <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-transparent" title="Options">
            <Image src={MoreVertival}/>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{right: "auto"}} className="stage-dropdown__menu">
            {
              can_access_content && inactiveSearchText &&
              <Dropdown.Item
                onClick={() => setShowAddContentGroupField(true)}
                className="stage-dropdown__button stage-dropdown__button__add"
              >
                <div className="svg-container">
                  <CircleAddIcon
                    color={'#727580'}
                  />
                </div>
                Add Card
              </Dropdown.Item>
            }
            {
              can_access_sort_stage &&
              <>
                <Dropdown.Item
                  onClick={() => handleSortByDueDateStage(stage.id, "asc")}
                  className="stage-dropdown__button stage-dropdown__button__sort"
                >
                  <div className="svg-container">
                    <UpArrowIcon
                      color={'#727580'}
                    />
                  </div>
                  Sort Ascending
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => handleSortByDueDateStage(stage.id, "desc")}
                  className="stage-dropdown__button stage-dropdown__button__sort"
                >
                  <div className="svg-container">
                    <DownArrowIcon
                      color={'#727580'}
                    />
                  </div>
                  Sort Descending
                </Dropdown.Item>
              </>
            }
            {
              can_access_stage &&
              <>
                <div
                  className="stage-dropdown__button stage-dropdown__button__revision dropdown-item"
                  onClick={ () => handleClickStageSetting('enable_revision') }
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="svg-container">
                        <EditIcon
                          color={'#727580'}
                        />
                      </div>
                      Revision Count
                    </div>
                    <Form.Check
                      type="switch"
                      name="enable_revision"
                      checked={enable_revision}
                      onChange={(e) => handleInlineStageEdit(e) }
                    />
                  </div>
                </div>
                <div
                  className="stage-dropdown__button dropdown-item"
                  onClick={ () => handleClickStageSetting('enable_timestamp') }
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="svg-container">
                        <TimeIcon
                          color={'#727580'}
                        />
                      </div>
                      Time Track
                    </div>
                    <Form.Check
                      type="switch"
                      name="enable_timestamp"
                      checked={enable_timestamp}
                      onChange={(e) => handleInlineStageEdit(e) }
                    />
                  </div>
                </div>
                <div
                  className="stage-dropdown__button dropdown-item"
                  onClick={ () => handleClickStageSetting('visible_to_client') }
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="svg-container">
                        <EyeIcon
                          color={'#727580'}
                        />
                      </div>
                      Visible to Partner
                    </div>
                    <Form.Check
                      type="switch"
                      name="visible_to_client"
                      checked={visible_to_client}
                      onChange={(e) => handleInlineStageEdit(e) }
                    />
                  </div>
                </div>

                <div
                  className="stage-dropdown__button dropdown-item stage-dropdown__button__approval"
                  onClick={ () => handleClickStageSetting('require_approval_from_client') }
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="svg-container">
                        <CheckIcon
                          color={'#727580'}
                        />
                      </div>
                      Require Approval
                    </div>
                    <Form.Check
                      type="switch"
                      name="require_approval_from_client"
                      checked={require_approval_from_client}
                      onChange={(e) => handleInlineStageEdit(e) }
                    />
                  </div>
                </div>
                <Dropdown.Item
                  onClick={() => handleShowDeleteStagePopup(stage.id)}
                  className="stage-dropdown__button stage-dropdown__button__delete"
                >
                  Delete Stage
                </Dropdown.Item>
              </>
            }
          </Dropdown.Menu>
        </Dropdown>
      }
    </>
  );
}

export { EditStageDropdown };
