import React, { useEffect, useState } from 'react';
import { work_spaceActions, notificationActions } from '../../../actions';
import { connect, useDispatch } from 'react-redux';
import { adminBaseURL } from '../../../utils/commonUtils';
import { UserAvatar } from '../../../components';
import { renderNameAndSummary } from '../../../utils';

function NotificationRow(props) {
  const dispatch = useDispatch();
  const { mode, className, key, notification_load } = props;
  const { data, setWorkspaceId, setContentGroupPopUp, currentUser } = props;
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const [isRead, setIsRead] = useState(data ? data.is_read : false);
  const readable = props.readable ? !isRead : false;

  useEffect(() => {
    if(data){
      setIsRead(data.is_read)
    }
  }, [data]);

  useEffect(() => {
    if (notification_load && notification_load.id === data.id && props.readable) {
      setIsRead(notification_load.is_read)
    }
  }, [notification_load]);

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest('a');
    if(!targetLink) return;
    e.preventDefault();
    var content_id = targetLink.getAttribute("data-contentId")
    var workspace_id = targetLink.getAttribute("data-workspaceId")
    sessionStorage.setItem("previous_url", window.location.pathname)
    setWorkspaceId(workspace_id)
    setContentGroupPopUp({id: content_id, show: true})
    return true
  }

  const handleRead = (notificationId) => {
    dispatch(notificationActions.read(notificationId));
  }

  const handleUnRead = (notificationId) => {
    dispatch(notificationActions.unRead(notificationId));
  }

  const workspaceClickHandler = (e, workspace) =>{
    e.stopPropagation();

    window.location = `/organizations/${workspace.organization_id}${workspaceBaseUrl}/${workspace?.id}/list`

    return true;
  }

  const activityDescriptionHtml = () => {
    const bold_name = mode === 'notification';

    return renderNameAndSummary(data?.actor?.name, data?.summary, bold_name, shouldDisplayNameAndActivityInOneLine());
  }

  const shouldDisplayNameAndActivityInOneLine = () => {
    return true;
  }

  return (
    <tr onClick={() => props.readable ? (isRead ? handleUnRead(data.id) : handleRead(data.id)) : null }>
      <td className={`${readable ? (isRead ? 'notifications read_message' : 'notifications unread_message') : 'notifications read_message' } ${className}`} onClick={contentClickHandler}>
        <div className="d-flex w-90">
          <div className="notification__image-wrapper">
            { data.actor &&
              <UserAvatar
                icon={ data.actor.icon }
                name={ data.actor.name }
                name_initials={ data.actor.name_initials }
                size="32"
                user_id={data.actor.user_id}
                workspace_id={ data.workspace_id }
                organization_id={ data.organization_id }
                openUserSummary={true}
              />
            }
          </div>
          <div className="w-100">
            { !shouldDisplayNameAndActivityInOneLine() &&
              <p className="notification__actor-name">{data.actor.name}</p>
            }

            <div className="notification__description" dangerouslySetInnerHTML={{ __html: activityDescriptionHtml() }} />
            <div className="notifications-dropdown__notification-timestamp" title={ data.created_at }>
              <small >
                <span className="mr-3">
                { data.created_at_time_from_now }
                </span>
                <span className="workspace_link" onClick={(e) => workspaceClickHandler(e, data)}>
                  { data.workspace_name }
                </span>
              </small>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.dashboard.currentUser,
    notification_load: state.notification.notification_load
  };
};

export default connect(mapStateToProps, {})(NotificationRow);
