import axios from 'axios';
import humps from 'humps';

import { store, history } from '../helpers';
import { sessionConstants } from '../constants';
import { alertActions } from '../actions';

let userToken = () => {
  return JSON.stringify({ user: JSON.parse(localStorage.getItem('user')), org_id: localStorage.getItem('org_id')})
};

export const convertRequest = (data) => humps.decamelizeKeys(data);
export const convertResponse = (data) => humps.camelizeKeys(data);

const API_URL = process.env.REACT_APP_API_URL;
const formatURL = (uri) => `${API_URL}${uri}`;

export const handleResponse = (response) => ({ ...convertResponse(response.data) });
export const errorResponse = (error) => ({ errors: convertResponse(error.response.data.errors) });

axios.interceptors.request.use(async (config) => {
  config.headers.Authorization = userToken();
  config.headers.post['Content-Type'] = "application/json";
  return config;
});

axios.interceptors.response.use(
  function(response) {
    if (window.location != '/login' && localStorage.getItem('user') && response.status == 200 && response.data && response.data.status == 'authentication_failure') {
      localStorage.clear();
      history.push('/login');

      store.dispatch(alertActions.error(sessionConstants.ALERT_SESSION_EXPIRED));
    }

    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export const makeGETRequest = (uri, queryParams = {}) => axios.get(formatURL(uri), { params: convertRequest(queryParams) });
export const makeGETBlobRequest = (uri, queryParams = {}) => axios.get(formatURL(uri), { params: convertRequest(queryParams), responseType: 'blob' });
export const makePOSTRequest = (uri, body, config = {}) => axios.post(formatURL(uri), convertRequest(body), config);
export const makePUTRequest = (uri, body, config = {}) => axios.put(formatURL(uri), convertRequest(body), config);
export const makeDELETERequest = (uri, body, config = {}) => axios.delete(formatURL(uri), convertRequest(body), config);
export const makeFormDataPOSTRequest = (uri, body, config = {}) => axios.post(formatURL(uri), body, config);
export const makeFormDataPUTRequest = (uri, body, config = {}) => axios.put(formatURL(uri), body, config);
