import { useState, forwardRef, useImperativeHandle } from 'react'
import { UserAvatar } from './'

const UserDropdown = forwardRef((props, ref) => {
  const { users, filterText, handleSelectUser, shouldBeFixed } = props

  const [ currentActiveIndex, setCurrentActiveIndex ] = useState(0)

  const filteredUsers = users.filter((user) => (user.user_full_name || '').toLowerCase().includes((filterText || '').toLowerCase()))

  useImperativeHandle(
    ref,
    () => ({
      handleKeyUp(event) {
        if (event.key == 'ArrowDown') {
          if (currentActiveIndex + 1 < filteredUsers.length) {
            setCurrentActiveIndex(currentActiveIndex + 1)
          }
        } else if (event.key == 'ArrowUp') {
          if (currentActiveIndex - 1 >= 0) {
            setCurrentActiveIndex(currentActiveIndex - 1)
          }
        } else if (event.key == 'Enter') {
          let activeUser = filteredUsers[currentActiveIndex]
          handleSelectUser(event, activeUser)
        }
      }
    }),
  )

  if (filteredUsers.length == 0 ) {
    return null
  }

  return (
    <ul className="user-dropdown" id={shouldBeFixed ? "postion_top" : "postion_bottom"}>
      { filteredUsers.map((user, userIndex) => (
        <li
          className={ userIndex == currentActiveIndex && 'user-dropdown__user--active' }
          key={userIndex}
        >
          <button onClick={ (event) => handleSelectUser(event, user) }>
            <UserAvatar
              icon={ user.user_logo && user.user_logo.original_url ? user.user_logo.original_url : null }
              name={ user.user_full_name }
              name_initials={ user.user_name_initials }
              size="30"
            />
            <div className="user-dropdown__user-info-wrapper">
              <div className="user-dropdown__user-name">
                { user.user_full_name }
              </div>

              { !!user.role_name &&
                <div className="user-dropdown__user-role">
                  { user.role_name }
                </div>
              }
            </div>
          </button>
        </li>
      ))}
    </ul>
  );
})

export { UserDropdown };
