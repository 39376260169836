import React from "react";
import { Field } from "redux-form";
import { textInput , selectField } from '../../../../shared/form-elements'

class InviteUserFields extends React.Component {
  render() {
    const { fields, editForm, roles } = this.props;
    const removeName = (index, invited_users) => {
      fields.remove(index);
    };
    return (
      fields.map((invited_users, index) => {
        return (
          <div key={index} className={index + "findClass_types row"} style={{ flexGrow: 1 }}>
            <div className="col-lg-9 col-md-7">
              <Field
                name={`${invited_users}.email`}
                type="text"
                component={textInput}
                label=""
                placeholder="Email Address"
              />
            </div>
            <div className="col-lg-3 col-md-5 dropdown_option" style={{paddingLeft: '5px'}}>
              <Field
                name={`${invited_users}.workspace_role_id`}
                component={selectField}
                options={roles?.workspace_roles}
                label=""
                blank_label="Select Role"
                exclude_blank_option={true}
              />
            </div>
          </div>
        );
      })
    );
  }
}

export default InviteUserFields;
