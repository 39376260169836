import React, { useEffect } from "react";
import { validation } from "../../../shared/form-validations";
import { FieldArray, reduxForm } from 'redux-form';
import addUsers from '../AssignedUserPage/shared/addUsers';
import { connect } from 'react-redux';
import CheckIcon from "../../../images/icons/white/check.svg";

function AssignedUserForm(props) {
  const { handleSubmit , status, content_group_id, workspace_id, assigned_users, initialize, participant_user_options, assigned_user_error, assigned_users_object } = props

  useEffect(() => {
    if(assigned_users_object && assigned_users_object.length > 0){
      props.initialize({
        assigned_users: assigned_users_object
      });
    }else {
      props.initialize({assigned_users: []});
    }
  }, [assigned_users_object]);

  return (
    <div className="assigned-users-popup__container">
      {assigned_user_error && assigned_user_error.length > 0 && assigned_user_error.map((data, index) => (
        <div className="error_message" key={index}>{data}</div>
      ))}

      <button type="button" className="btn-close" aria-label="Close" onClick={() => props.closeDropDownPopup(status)}/>

      <form className="dropdown--with-check" onSubmit={handleSubmit}>
        <h4 className="mb-2 text-left font-weight-bold assigned-users-popup__title">Participants</h4>

        <FieldArray
          name='assigned_users'
          component={addUsers}
          users={participant_user_options}
          change={props.change}
        />

        <div className="d-flex justify-content-end submit_button">
          <button
            className="btn btn-primary"
            type="submit">
            <img src={CheckIcon} className="button-icon" />
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

AssignedUserForm = reduxForm({
  form: "assignedUserForm"
})(AssignedUserForm);

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps, {  })(AssignedUserForm);
