export const userConstants = {
  RESPONSE_GOOGLE_REQUEST: 'Response google request',
  RESPONSE_GOOGLE_SUCCESS: 'Response google successfully',
  RESPONSE_GOOGLE_FAILURE: 'Response google failed',

  REGISTER_REQUEST: 'Register request',
  REGISTER_SUCCESS: 'Registered successfully, please check your inbox to verify the email address',
  REGISTER_SUCCESS_VERIFIED: 'Registered successfully',
  REGISTER_FAILURE: 'Registration failed',

  CONFIRM_FAILURE: 'Confirm email failed',
  CONFIRM_SUCCESS: 'Confirmed email successfully',

  LOGIN_REQUEST: 'Login request',
  LOGIN_USER: 'Login user',
  LOGIN_SUCCESS: 'Login successfully',
  LOGIN_FAILURE: 'Login failed',
  LOGIN_ALREADY: 'User is already logged in.',

  LOGOUT: 'Logout successfully',

  GETALL_REQUEST: 'Get users request',
  GETALL_SUCCESS: 'Get users successfully',
  GETALL_FAILURE: 'Get users failed',

  DELETE_REQUEST: 'User delete request',
  DELETE_SUCCESS: 'Deleted successfully',
  DELETE_FAILURE: 'Delete failed',

  LABEL_REQUEST: 'Created label successfully',
  LABEL_FAILURE: 'Label failed',
  GET_LABEL: 'Get label',
  EDIT_LABEL: 'Edit label',
  DELETE_LABEL: 'Deleted label successfully',
  UPDATE_LABEL: 'Updated label successfully',
  LABEL_LOADING: 'Label loading',
  ASSIGN_CONTENT_LABEL_TO_CONTENT_GROUP: 'Assigned label to content',
  ASSIGN_CONTENT_LABEL_TO_CONTENT_GROUP_FAILURE: 'Assignment of label to content failed',

  PROJECT_REQUEST: 'Created label successfully',
  PROJECT_FAILURE: 'Label failed',
  GET_PROJECT: 'Get label',
  EDIT_PROJECT: 'Edit label',
  DELETE_PROJECT: 'Deleted label successfully',
  UPDATE_PROJECT: 'Updated label successfully',
  PROJECT_LOADING: 'Label loading',
  PAGE_LOADING: 'Loading...',

  WORKSPACE_REQUEST: 'Created workspace successfully',
  WORKSPACE_FAILURE: 'Workspace failed',
  GET_WORKSPACE: 'Get workspace',
  WORKSPACE_LOADING: 'Workspace loading',
  WORKSPACE_DROPDOWN_LOADING: 'Workspace dropdown loading',
  EDIT_WORKSPACE: 'Edit workspace',
  DELETE_WORKSPACE: 'Deleted workspace successfully',
  UPDATE_WORKSPACE: 'Updated workspace successfully',
  WORKSPACE_VIEW_CHANGE: "Change workspace view",
  UPDATE_CONTENTGROUP_WORKSPACE: 'updated content group details',
  ASSIGN_LABEL_IN_CONTENT: 'assign label in content',
  CONTENT_NOT_AVAILABLE: 'The content cannot be found.',

  USERPROFILE_FAILURE: 'User profile failed',
  EDIT_USERPROFILE: 'Edit user profile',
  DELETE_USERPROFILE: 'Deleted user profile successfully',
  UPDATE_USERPROFILE: 'Updated user profile successfully',
  UPDATE_USERPASSWORD: 'Updated user profile successfully',

  GET_WORKSPACE_DATA: 'Get all workspace details',
  CONTENT_REQUEST: 'Created content successfully',
  CONTENT_FAILURE: 'Content failed',
  CONTENT_SHOW: 'Content show',
  CONTENT_HIDE: 'Content hide successfully',
  CONTENT_UNHIDE: 'Content unhide successfully',
  GET_CONTENT: 'Get content',
  EDIT_CONTENT: 'Edit content',
  DELETE_CONTENT: 'Deleted content successfully',
  UPDATE_CONTENT: 'Updated content successfully',
  NEW_CONTENT: "New content",
  CONTENT_VERSION: "Content versions",
  CONTENT_VERSION_SHOW: "Content versions show",
  CONTENT_INLINE_EDIT: "content inline edt",

  CONTENT_LOADING: "Content loading",
  SET_SEARCH_HISTORY_CONTENTGROUP: 'SET_SEARCH_HISTORY_CONTENTGROUP',

  CREATED_CONTENTGROUP: 'CREATED_CONTENTGROUP',
  DESTROYED_CONTENTGROUP: 'DESTROYED_CONTENTGROUP',
  CONTENTGROUP_REQUEST: 'Created content group successfully',
  CONTENTGROUP_FAILURE: 'Content group failure',
  NEW_CONTENTGROUP: 'New content group',
  GET_CONTENTGROUP: 'Get content group',
  EDIT_CONTENTGROUP: 'Edit content group',
  ARCHIVE_CONTENTGROUP: 'Archived content group successfully',
  UNARCHIVE_CONTENTGROUP: 'Unarchived content group successfully',
  CONTENTGROUP_LOADING: 'Content group loading',
  DELETE_CONTENTGROUP: 'Deleted content group successfully',
  UPDATE_CONTENTGROUP: 'Updated content group successfully',
  SUBMIT_CONTENTGROUP: 'Submitted successfully for approval',
  VOID_SUBMIT_CONTENTGROUP: 'Voided submit successfully',
  APPROVE_CONTENTGROUP: 'Approve card successfully',
  REJECT_CONTENTGROUP: "Requested revision for approval request",
  ACCEPT_REQUEST_CONTENTGROUP: 'Requested revision accepted',
  REJECT_REQUEST_CONTENTGROUP: 'Requested revision rejected',
  REPLACE_CONTENT_IMAGE: 'replace image',

  COMMENT_FAILURE: "Comment failure",
  COMMENT_REQUEST: "Created comment successfully",
  DELETE_COMMENT: "Delted comment successfully",
  GET_COMMENT: "Get comment",
  COMMENT_LOADING: "Comment loading",
  CHAT_SCROLL_UPDATE: "chat scroll update",
  CHAT_SCROLL_INITIAL_LOAD: "chat scroll initial load",
  CREATED_COMMENT: 'CREATED_COMMENT',

  WORKSPACE_USER_NEW: 'New workspace',
  WORKSPACE_USERS_LIST: 'Workspace user list',
  WORKSPACE_USERS_EDIT: 'Workspace user edit',
  WORKSPACE_USERS_UPDATE: 'Updated workspace user',
  WORKSPACE_INITIAL_DATA: 'Workspace initial data',
  WORKSPACE_SHOW: 'Show workspace',
  WORKSPACE_USER_FAILURE: 'Workspace user failed',

  USER_SUMMARY: 'Show workspace user',
  USER_SUMMARY_LOADING: 'User summary loading',
  USER_PROFILE: 'User profile',
  USER_PROFILE_LOADING: 'User profile loading',
  USER_SUMMARY_RESET: 'USER_SUMMARY_RESET',

  NEWPASSWORD_REQUEST: 'Password request',
  NEWPASSWORD_SUCCESS: 'Email sent',
  NEWPASSWORD_FAILURE: 'New password failed',

  CHANGEPASSWORD_REQUEST: 'Change password request',
  CHANGEPASSWORD_SUCCESS: 'Password changed successfully.',
  CHANGEPASSWORD_FAILURE: 'Change password failed',

  WORKSPACE_LIST_DATA: 'Workspace list data',
  WORKSPACE_USER_INITIAL_DATA: 'Workspace user initial data',
  WORKSPACE_USER_INITIAL_FAILURE: 'Workspace user initial data failed',
  WORKSPACE_USER_NEW: 'New workspace user',
  WORKSPACE_USER_FAILURE: 'Workspace user failed',
  WORKSPACE_USER_DESTROY: 'User destroyed',
  WORKSPACE_USER_DESTROY_SUCCESS: 'User destroyed successfully',
  RESENT_INVITE_USER: 'Resent invitations',
  RESENT_NOTIFICATIONS: 'Resent notifcations',
  INVITE_USER_DESTROY: 'Invited user deleted',
  INVITE_USER_DESTROY_SUCESS: 'Deleted invited user successfully',
  LIST_COLLABORATORS: 'Collaborators list',

  DASHBOARD_INITIAL_DATA: 'Dashboard initial data',

  NOTIFICATIONS_LOADING: 'Notifications loading...',
  PENDING_NOTIFICATION: 'Get all pending invitations',
  ACCEPT_INVITATION: 'Accept invitations',
  All_ACCEPT_INVITATION: 'Accept all invitations',

  COMMON_INITIAL_DATA: "Gel all common details",
  WORKSPACES_DATA: "Get workspaces",
  CURRENT_USER_DATA: "Get current user details",
  PENDING_NOTIFICATION_COUNT: "Get pending notifcation count",
  CONTENT_FIRST_RECORD: "Get content first record ",
  COMMON_INITIAL_DATA_LOADING: "Get all common details load",

  RECOMMEND_CONTENTGROUP: 'RECOMMEND_CONTENTGROUP',
  RECOMMEND_CONTENTGROUP_LOADING: 'RECOMMEND_CONTENTGROUP_LOADING',

  STAGE_REQUEST: 'Stage created successfully',
  STAGE_FAILURE: 'Stage failed',
  NEW_STAGE: 'New stage',
  UPDATE_STAGE: 'Stage updated successfully',
  GET_STAGE: 'Get Stage',
  EDIT_STAGE: 'Edit stage',
  DELETE_STAGE: 'Deleted stage successfully',
  STAGE_LOADING: 'Stage loading',
  STAGE_CREATE_REQUEST: 'stage create request',
  STAGE_DELETE_REQUEST: 'stage delete request',
  STAGE_UPDATE_REQUEST: 'stage update request',
  STAGES_TO_UPDATE: 'stages to update',

  TEMPLATESTAGE_REQUEST: 'Created board stage successfully',
  TEMPLATESTAGE_FAILURE: 'Board stage failed',
  GET_TEMPLATESTAGE: 'Get board stage',
  EDIT_TEMPLATESTAGE: 'Edit board stage',
  NEW_TEMPLATESTAGE: 'New board stage',
  DELETE_TEMPLATESTAGE: 'Deleted board stage successfully',
  UPDATE_TEMPLATESTAGE: 'Updated board stage successfully',
  TEMPLATESTAGE_LOADING: "Board stage loading",

  CREATE_CONVERSATION: 'Created new conversation',
  SENDER_MESSAGE: 'sender message',
  SENDER_ERROR: 'sender error',
  CURRENT_MESSAGE: 'new message',
  MESSAGE_COUNT: 'show message count',

  NEW_COMMENT: 'new comment message',
  GET_CURRENT_MESSAGE_COUNT: 'current message count',

  GET_STAGE_LIST_INITIAL_DATA: 'Get stage list initial data',
  GET_CALENDAR_DATA: 'Get calendar data',

  GET_AUTHORIZED_USERS: 'Get Authorized Users',

  GET_ASSIGNED_USER: 'Get assigned user',
  ASSIGNED_USER_FAILURE: 'Assigned user failed',
  NEW_ASSIGNED_USER: 'New assigned user',
  ASSIGNED_USER_REQUEST: 'Assigned users successfully',
  DELETE_ASSIGNED_USER: 'Deleted assigned user successfully',
  ASSIGNED_USER_LOADING: 'Assigned user loading',
  UNCHECKED_VALUE: 'unchecked value',
  CURRENT_USER_PERMISSION: 'current user permission',

  CONTENT_LABEL_FAILURE: 'Content failure',
  CONTENT_LABEL_REQUEST: 'Content Label Created Successfully',
  CONTENT_LABEL_LOADING: 'loading',
  UPDATE_CONTENT_LABEL: 'Update Content Label Successfully',
  GET_CONTENT_LABEL: 'get content labels',
  DELETE_CONTENT_LABEL: 'delete content labels',

  COMMENT_ATTACHMENT_FAILURE: 'Comment attachment failure',

  REMOVE_CONTENT_GROUP: 'Remove Content group',
  DELETE_SEARCH_CONTENTGROUP: 'DELETE_SEARCH_CONTENTGROUP',
  SEARCH_CONTENTGROUP: 'Search Content Group',
  SEARCH_CONTENTGROUP_LOADING: 'Search Content Group loading',
  RESET_RECOMMENDATION_CONTENTGROUP: 'RESET_RECOMMENDATION_CONTENTGROUP',
  ACTIVITY_LOADING: 'ACTIVITY_LOADING',
  GET_ACTIVITY: 'GET_ACTIVITY',
  ACTIVITY_FAILURE: 'ACTIVITY_FAILURE',

  GET_SEARCH_HISTORIES: 'GET_SEARCH_HISTORIES',
  SEARCH_HISTORY_FAILURE: 'SEARCH_HISTORY_FAILURE',
  CREATE_SEARCH_HISTORY: 'CREATE_SEARCH_HISTORY',
  DELETE_SEARCH_HISTORY: 'DELETE_SEARCH_HISTORY',

  CONTENT_INLINE_UPDATE: 'CONTENT_INLINE_UPDATE',

  STAGE_SORT: 'Sorted Successfully',

  ORGANIZATION_LOADING: 'Organization Loading',
  GET_ORGANIZATIONS: 'GET_ORGANIZATIONS',
  SET_CURRENT_ORGANIZATION: 'SET_CURRENT_ORGANIZATION',
  ORGANIZATION_FAILURE: 'Organization is invalid',
  RESET_ORGANIZATION: 'RESET_ORGANIZATION',
  EDIT_ORGANIZATION: 'EDIT_ORGANIZATION',
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',
  CREATE_ORGANIZATION: 'Organization Created Successfully',
  DESTROY_ORGANIZATION: 'Organization Deleted Successfully',
  SET_ORGANIZATION_ALLOWED_USER_DETAILS: 'SET_ORGANIZATION_ALLOWED_USER_DETAILS',

  SET_INVITATION: 'SET_INVITATION',
  REMOVE_INVITATION: 'REMOVE_INVITATION'
};
