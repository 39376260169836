import { useState, useEffect } from "react";
export const useDetectOutsideClick = (el, initialState, delayed_click = 0) => {
  const [isActive, setIsActive] = useState(initialState);
  const [ignoredClicks, setIgnoredClicks] = useState(delayed_click)
  useEffect(() => {
    const onClick = e => {
      if (el.current !== null && !el.current.contains(e.target) && !e.target.classList.contains('shares_popup') && ignoredClicks <= 0) {
        setIsActive(false);
        setIgnoredClicks(delayed_click);
      } else {
        setIgnoredClicks(prev => prev - 1)
      }
    };

    if (isActive) {
      window.addEventListener("click", onClick);
    }

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isActive, ignoredClicks, el]);

  return [isActive, setIsActive];
};
