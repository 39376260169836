import { useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { getLabelColor } from './../helpers';
import { CloseIcon } from './';

function ContentLabel(props) {
  const {
    id,
    custom_close_button_id_text,
    name,
    color_hex,
    labels,
    editable,
    handleDelete,
    custom_close_button_bg_color,
    sharesPopup
  } = props
  let labelRef = useRef();
  const label_color = getLabelColor(color_hex);

  let close_button_style = {
    backgroundColor: "#"+color_hex
  }

  return (
    <div className="content-label__container" id={'content_label'+id} key={'content_label'+id}>
      <span
        className={`content-label ${editable === false && 'non-editable'} ${sharesPopup && 'shares_popup'}`}
        ref={labelRef}
        style={{
          backgroundColor: "#"+label_color.background,
          color: label_color.text,
          cursor: !!editable ? 'pointer' : 'default'
        }}
      >

        {name}
      </span>

      {
        editable &&
        <div
          className='content-label__close-button__container'
          style={ custom_close_button_bg_color ? { backgroundColor: custom_close_button_bg_color} : {}}
        >
          <div
            id={ custom_close_button_id_text && id ? custom_close_button_id_text +''+ id : ''}
            className='content-label__close-button'
            style={close_button_style}
            onClick={(e) => handleDelete(id, e) }
          >
            <CloseIcon color={label_color?.text}/>
          </div>
        </div>
      }
    </div>
  );
}

export { ContentLabel };
