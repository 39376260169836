import React from "react";
import { NavDropdown } from 'react-bootstrap';
import CheckIcon from '../../images/icons/check.svg';

const renderName =(option, optionname) =>{
  switch(optionname){
    case "user":
     return option.first_name+" "+option.last_name;
    case "user-w-email":
      return option.first_name+" "+option.last_name+" ("+option.email+")"
    default:
      return option.name
  }
}

const handleClick = (event, input, value) => {
  input.onChange(value)
}

export const selectField = ({ input, label, blank_label, exclude_blank_option, options, optionname,  type, meta: { touched, error }, ...rest }) => {
  const selectedOptions = options && options.filter((option) => option.id == input.value)
  const selectedOption = selectedOptions && selectedOptions.length > 0 && selectedOptions[0]

  return (
    <div>
      { label &&
        <label className="mb-1" style={{fontSize: "14px"}}> {label} </label>
      }

      <NavDropdown {...input} title={ selectedOption ? selectedOption.name : blank_label } className={ "form-control dropdown--with-check" + (rest.input_class_name ? " " + rest.input_class_name : "") }>
        { !exclude_blank_option &&
          <NavDropdown.Item key="" onClick={(e) => handleClick(e, input, "") }>
            { blank_label }

            { "" == input.value &&
              <span className="dropdown__selected--with-check">
                <img src={CheckIcon} alt="Selected" />
              </span>
            }
          </NavDropdown.Item>
        }

        { options && options.map((option, index) => (
          <NavDropdown.Item key={option.id} onClick={(e) => handleClick(e, input, option.id) }>
            { renderName(option, optionname) }

            { option.id == input.value &&
              <span className="dropdown__selected--with-check">
                <img src={CheckIcon} alt="Selected" />
              </span>
            }
          </NavDropdown.Item>
          ))
        }
      </NavDropdown>

      {touched && error && (
      <span className="form-error">{error}</span>
      )}
    </div>
  )
}

export default selectField
