import React from "react";
import { Field } from "redux-form";
import { textInput, selectField } from '../../../../shared/form-elements'

class renderWorkspace extends React.Component {
  render() {
    const { fields, workspace, roles, editForm } = this.props;
    const removeName = (index, workspace_users) => {
      if (editForm === 'editForm') {
        this.props.change(workspace_users + '._destroy', true)
        const className = index + 'findClass_row'
        const currentClass = document.getElementsByClassName(className)
        for (let i = 0; i < currentClass.length; i++) {
          currentClass[i].classList.toggle('hidden')
        }
      } else {
        fields.remove(index)
      }
    }


    return (
      <div>
        {fields.map((workspace_users, index) => {
          return (
            <div key={index} className={index + "findClass_row"}>
              <div className="row d-flex align-items-center mb-3">
                <Field
                  name={`${workspace_users}.id`}
                  component={textInput}
                  type={"hidden"}
                />
                <div className="col-lg-5">
                  <Field
                    name={`${workspace_users}.workspace_id`}
                    component={selectField}
                    options={workspace}
                    label="Select Workspace"
                    blank_label="Select Workspace"
                  />
                </div>

                <div className="col-lg-6">
                  <Field
                    name={`${workspace_users}.workspace_role_id`}
                    component={selectField}
                    options={roles}
                    label="Select Workspace Role"
                    blank_label="Select Workspace Role"
                  />
                </div>
                <div className={"col-lg-1"}>
                  <div key={index} className="mt-4">
                    <div onClick={() => removeName(index, workspace_users)} className="remove_btn">Remove</div>
                  </div>
                </div>
                 <div className="hidden">
                    <Field
                      name={`${workspace_users}._destroy`}
                      component={textInput}
                      key={index}
                      removeClass="removeClass"
                    />
                  </div>
              </div>
            </div>
          );
        })}

        <div className="py-2">
          <div  onClick={() => fields.push({})} className="addmore_btn"> + Add More</div>
        </div>
      </div>
    );
  }
}

export default renderWorkspace;
