import React, { useEffect, useState, useRef, useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { work_spaceActions, content_groupActions } from "../../../actions";
import { buildFormDataFromObject } from '../../../utils/commonUtils';
import { useLocation, useParams } from "react-router-dom";
import ContentGroupList from "../ContentGroupPage/list";
import { LoadSpinner, SortContentGroup, CircleAddIcon } from '../../../components';
import { Row, Col } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import EmptyStage from '../../../images/empty-stage.png';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { WorkspaceShowContext } from "../../../contexts/WorkspaceShowContext";

function ContentGroupsTables(props) {
  const {
    workspace_id,
    stages,
    handleShowNewForm,
    setNewStageFormPopup,
    can_crud_content_group,
    handleInlineEdit,
    can_access_edit_title,
    can_access_assigned_user,
    can_access_show_content_details,
    can_access_move_content_list,
    can_access_add_change_date,
    can_access_approval_dots,
    can_access_edit_label,
    can_access_move_stage_board,
    replace_image
  } = props;

  const dispatch = useDispatch();
  const work_space = useSelector(state => state.work_space.currentWorkSpace);

  const getContentGroups = (obj) => {
    return obj.content_groups.map(item => ({
      id: item.id.toString(),
      name: item.name,
      stage_id: item.stage_id,
      first_record: item.first_record,
      preview: item.preview,
      due_date: item.due_date,
      updated_at: item.updated_at,
      discussion_count: item.discussion_count,
      internal_note_count: item.internal_note_count,
      index: item.index,
      assigned_users: item.assigned_users,
      labels: item.labels,
      description: item.description,
      stage: item.stage
    }))
  }

  const {
    can_access_stage,
    rows,
    setRows,
    content_groups,
    setContentGroups,
    getStages,
    updateRecords,
    nextPage,
    updated_at,
    addContentGroupToStage,
    removeContentGroupToStage
  } = useContext(WorkspaceShowContext);

  useEffect(() => {
    replace_image && replace_image.map((data, i) => {
      if(data && data.attachment) {
        var replaceImage = document.querySelector(`#find_div_${data.id} img`);
        if (!!replaceImage) {
          replaceImage.src = data.attachment.icon_square.url;
        }
      }
    })
  }, [replace_image, rows])

  useEffect(() => {
    const timer = setTimeout(() => {
      const visibleDivObserver = new IntersectionObserver((entries, imgObserver) => {
        var collect_id = []
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const find_value = entry.target
            if (find_value.getAttribute('data-image-pending') == 'true') {
              collect_id.push(find_value.getAttribute('data-id'));
              find_value.setAttribute('data-image-pending', 'false');
            }
          }
        })
        if(collect_id.length > 0) {
          var content_groups_id = collect_id
          dispatch(content_groupActions.replaceContentImageLazeLoading(workspace_id, content_groups_id ));
        }
      });

      var visible_div = document.querySelectorAll("#find_parentDiv > div > div > div > div .visible_div")
      visible_div.forEach((v) => {
        visibleDivObserver.observe(v);
      })
    }, 300);
    return () => clearTimeout(timer);
  }, [rows]);


  useEffect(() => {
    updateRecords(props);
  }, []);

  useEffect(() => {
    updateRecords(props);
  }, [props.stages]);

  const updateIndexContentGroup = (newRow, content_drag_id, source_stage_id, destination_stage_id, type) => {
    const hash = newRow.map((row) => ({
      id: row.id,
      items: row.items.map((item) => ({
        id: item.id
      }))
    }))
    let formData = new FormData();
    buildFormDataFromObject(formData, hash, "sort");
    dispatch(work_spaceActions.updateSortContentGroupWorkSpace(formData, workspace_id, [content_drag_id], source_stage_id, destination_stage_id, type));
  }

  const onDragEnd = (result, rows, setRows) => {
    const { source, destination, type, draggableId } = result;
    let newRow = []
    var source_stage_id = ""
    var destination_stage_id = ""
    let content_drag_id = ""
    if (!destination) return;

    if (type == 'COLUMN') {
      const newRow = rows
      const [removed] = newRow.splice(source.index, 1);
      newRow.splice(destination.index, 0, removed);
      setRows(newRow);
      content_drag_id = draggableId

      updateIndexContentGroup(newRow, content_drag_id, source_stage_id, destination_stage_id, type)
    } else {
      content_drag_id = draggableId.replace(/column-/g,'');

      if (source.droppableId !== destination.droppableId) {
        let sourceRow = rows.find((element) => element.id == source.droppableId);
        let destRow = rows.find((element) => element.id == destination.droppableId);
        source_stage_id = sourceRow.id
        destination_stage_id = destRow.id
        const sourceItems = [...sourceRow.items];
        const destItems = [...destRow.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);

        destRow.items = destItems;
        sourceRow.items = sourceItems;

        rows = rows.filter(el => el.id !== source.droppableId || el.id !== destination.droppableId)

        newRow = [
          ...rows
        ]
        setRows(newRow);
      }else {
        const row = rows.find((element) => element.id == source.droppableId);
        const index = rows.findIndex((element) => element.id == source.droppableId);
        const copiedItems = [...row.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);

        row.items = copiedItems;

        rows[index] = row;

        newRow = [
          ...rows
        ]
        setRows(newRow)
      }
    }

    updateIndexContentGroup(newRow, content_drag_id, source_stage_id, destination_stage_id, type)
  }

  return (
    <div style={{ height: "100%", overflowY: "auto" }} id="find_parentDiv">
      <div className="container layout__container-wrapper container--workspace-list">
         <DragDropContext  onDragEnd={result => onDragEnd(result, rows, setRows)}>
          <Droppable
            droppableId="LIST"
            type="COLUMN"
            direction="vertical"
          >
            {(provided, snapshot) => {
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  { rows.map((data, index) => (
                    <div key={index}>
                      <ContentGroupList
                        stage={data}
                        rows={data}
                        content_groups={props.content_groups}
                        index={index}
                        stage_id={data.id}
                        workspace_id={workspace_id}
                        updated_at={updated_at}
                        stage_name={data.name}
                        handleInlineEdit = {handleInlineEdit}
                        can_access_edit_title={can_access_edit_title}
                        can_access_assigned_user={can_access_assigned_user}
                        can_access_show_content_details={can_access_show_content_details}
                        can_access_move_content_list={can_access_move_content_list}
                        can_access_add_change_date={can_access_add_change_date}
                        can_access_approval_dots={can_access_approval_dots}
                        can_access_edit_label={can_access_edit_label}
                        can_access_move_stage_board={can_access_move_stage_board}
                        allowed_stages={stages}
                        getStages={getStages}
                        setRows={setRows}
                        addContentGroupToStage={addContentGroupToStage}
                        loadNextPage={nextPage}
                        removeContentGroupToStage={removeContentGroupToStage}
                      />
                    </div>
                  ))}
                  {provided.placeholder}
                </div>
              )
            }}
          </Droppable>
        </DragDropContext>

        {
          stages.length == 0  &&
          <div style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '2rem' }}>
            <img src={ EmptyStage } style={{ width: "500px", maxWidth: '100%', textAlign: 'center' }} />
            <p className="mt-3" style={{ fontWeight: '600', fontSize: '1.4rem' }}>This workspace has no stage yet.</p>
          </div>
        }

        {can_access_stage && (
          <div style={{ textAlign: 'center' }}>
            <Button variant="brand" onClick={() => setNewStageFormPopup(true)}>
              <div className="mr-2 d-inline-block">
                <CircleAddIcon color="white"/>
              </div>
              Add stage
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    replace_image: state.content_group.replace_image,
    loading: (state.work_space.loading && state.content_group.loading)
  };
};

export default connect(mapStateToProps, {  })(ContentGroupsTables);
