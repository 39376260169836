import React, { useEffect, useState } from 'react';
import {connect,  useDispatch, useSelector  } from 'react-redux';
import { NavDropdown } from 'react-bootstrap';
import { workspace_userActions } from '../../../actions';
import CheckIcon from '../../../images/icons/check.svg';

function SingleInviteForm(props) {
  const dispatch = useDispatch();
  const { workspaceId, getCommonData, roles, user_id, userType} = props
  const setInitialCommonData = getCommonData
  const [roleName, setRoleName] = useState(roles)

  const currentRole = userType && roles && roles.length > 0 ? roles[0].workspace_role_name : roles[0]

  const handleClick = (e, value) => {
    let successCallback = () => {}
    let values = {workspace_role_id: value, user_id: user_id}
    dispatch(workspace_userActions.updateWorkspaceUserRole(workspaceId, values, successCallback));
  }



  return (
    <NavDropdown title={ currentRole === null || currentRole === undefined ? 'Select Role' : currentRole } className="form-control role_select_options dropdown--with-check">
      { setInitialCommonData && setInitialCommonData.workspace_roles.map(option => (
        <NavDropdown.Item key={option.id} onClick={(e) => handleClick(e, option.id) }>
          { option.name }

          { option.name === currentRole &&
            <span className="dropdown__selected--with-check">
              <img src={CheckIcon} alt="Selected" />
            </span>
          }
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}

const mapStateToProps = (state) => {
  return {
    getCommonData: state.dashboard.getCommonData,
  };
};

export default connect(mapStateToProps,{ workspace_userActions })(SingleInviteForm);
